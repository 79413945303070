<template>
  <div>
    <el-container >

      <el-aside class="topShowAside">
        <div class="my_logo_show">
          <img class="my_logo" src="/static/imgs/login/login_logo_white.png" alt="慧台账电脑版">
          <h1 class="my_log_desc" v-if="is_com">慧台账电脑版</h1>
          <h1 class="my_log_desc" v-else>牡丹江市慧台账</h1>
        </div>
        <el-scrollbar class="asideBar">
          <el-row>
            <!--默认展开：default-active="1-4-1"-->
            <el-menu class="el-menu-vertical-demo" :collapse="isCollapse"
                     background-color="#211515"
                     text-color="#fff"
                     active-text-color="#ffd04b"
                     :default-openeds="openeds"
            >

              <el-menu-item index="1" class="mainPage menuItem" id="admin-index" @click="toAdminIndex">
                <i class="el-icon-s-home"></i>
                <span slot="title">首页</span>
              </el-menu-item>
              <el-submenu index="2">
                <template slot="title">
                  <i class="el-icon-s-order"></i>
                  <span slot="title">进货管理</span>
                </template>
                <el-menu-item-group>
                  <!--<span slot="title">分组一</span>-->
                  <el-menu-item v-show="getAuthorityMenu('2001')" index="2-1" id="stock-order" class="menuItem" @click="toStockOrder">进货开单</el-menu-item>
                  <el-menu-item v-show="getAuthorityMenu('2002')" index="2-2" id="stock-orderList" class="menuItem" @click="toStockOrderList">进货单据
                  </el-menu-item>
                  <el-menu-item v-show="getAuthorityMenu('2003')" index="2-3" id="stock-order-return" class="menuItem" @click="toStockOrderReturn">进货退货
                  </el-menu-item>
                  <el-menu-item v-show="getAuthorityMenu('2004')" index="2-4" id="stock-order-returnList" class="menuItem"
                                @click="toStockOrderReturnList">
                    进货退货单据
                  </el-menu-item>
                </el-menu-item-group>
                <!--<el-submenu index="1-4">
                  <span slot="title">选项4</span>
                  <el-menu-item index="1-4-1">选项1</el-menu-item>
                </el-submenu>-->
              </el-submenu>

              <el-submenu index="3">
                <template slot="title">
                  <i class="el-icon-menu"></i>
                  <span slot="title">销货管理</span>
                </template>
                <el-menu-item-group>
                  <el-menu-item v-show="getAuthorityMenu('3001')" index="3-1" id="sale-order" class="menuItem" @click="toSaleOrder">销货开单</el-menu-item>
                  <el-menu-item v-show="getAuthorityMenu('3002')" index="3-2" id="sale-orderList" class="menuItem" @click="toSaleOrderList">销货单据
                  </el-menu-item>
                  <el-menu-item v-show="getAuthorityMenu('3003')" index="3-3" id="sale-order-return" class="menuItem" @click="toSaleOrderReturn">销货退货
                  </el-menu-item>
                  <el-menu-item v-show="getAuthorityMenu('3004')" index="3-4" id="sale-order-returnList" class="menuItem" @click="toSaleOrderReturnList">
                    销货退货单据
                  </el-menu-item>
                </el-menu-item-group>
              </el-submenu>

              <el-submenu index="4">
                <template slot="title">
                  <i class="el-icon-document"></i>
                  <span slot="title">回收处置</span>
                </template>
                <el-menu-item-group>
                  <el-menu-item v-show="getAuthorityMenu('4001')" index="4-1" id="recycle-order" class="menuItem" @click="toRecycleOrder">回收开单
                  </el-menu-item>
                  <el-menu-item v-show="getAuthorityMenu('4002')" index="4-2" id="recycle-orderList" class="menuItem" @click="toRecycleOrderList">回收单据
                  </el-menu-item>
                  <el-menu-item v-show="getAuthorityMenu('4003')" index="4-3" id="recycle-order-return" class="menuItem" @click="toRecycleOrderReturn">
                    处置开单
                  </el-menu-item>
                  <el-menu-item v-show="getAuthorityMenu('4004')" index="4-4" id="recycle-order-returnList" class="menuItem"
                                @click="toRecycleOrderReturnList">处置单据
                  </el-menu-item>
                </el-menu-item-group>
              </el-submenu>

              <el-submenu index="5">
                <template slot="title">
                  <i class="el-icon-setting"></i>
                  <span slot="title">基础管理</span>
                </template>
                <el-menu-item-group>
                  <el-menu-item v-show="getAuthorityMenu('5001')" index="5-1" id="goods-manager" class="menuItem" @click="toGoodsManager">商品管理
                  </el-menu-item>
                  <el-menu-item v-show="getAuthorityMenu('5002')" index="5-2" id="client-manager" class="menuItem" @click="toClientManager">客户管理
                  </el-menu-item>
                  <el-menu-item v-show="getAuthorityMenu('5003')" index="5-3" id="supplier-manager" class="menuItem" @click="toSupplierManager">供应商管理
                  </el-menu-item>
                  <el-menu-item v-show="getAuthorityMenu('5004')" index="5-4" id="recipel-manager" class="menuItem" @click="toRecipelManager">处方管理
                  </el-menu-item>
                </el-menu-item-group>
              </el-submenu>

              <el-submenu index="6" >
                <template slot="title">
                  <i class="el-icon-user-solid"></i>
                  <span slot="title">系统管理</span>
                </template>
                <el-menu-item-group>
                  <el-menu-item v-show="getAuthorityMenu('6001')" index="6-1" id="user-manager" class="menuItem" @click="toUserManager">店铺缴费</el-menu-item>
                  <el-menu-item v-show="getAuthorityMenu('6002')" index="6-2" id="privilege-manager" class="menuItem" @click="toPrivilegeManager">权限设置</el-menu-item>
                  <el-menu-item v-show="getAuthorityMenu('6003')" index="6-3" id="user-role-manager" class="menuItem" @click="toUserRoleManager">用户管理</el-menu-item>
                  <el-menu-item v-show="getAuthorityMenu('6004')" index="6-4" id="role-manager" class="menuItem" @click="toRoleManager">角色管理</el-menu-item>
                  <el-menu-item v-show="getAuthorityMenu('6005')" index="6-5" id="resource-manager" class="menuItem" @click="toResourceManager">资源管理</el-menu-item>
                </el-menu-item-group>
              </el-submenu>

              <el-submenu index="7" >
                <template slot="title">
                  <i class="myIcon glyphicon glyphicon-open"></i>
                  <span slot="title">台账上报</span>
                </template>
                <el-menu-item-group>
                  <el-menu-item v-show="getAuthorityMenu('7001')" index="7-1" id="stock-report" class="menuItem" @click="toStockReport">进货台账上报</el-menu-item>
                  <el-menu-item v-show="getAuthorityMenu('7002')" index="7-2" id="sale-report" class="menuItem" @click="toSaleReport">销货台账上报</el-menu-item>
                  <el-menu-item v-show="getAuthorityMenu('7003')" index="7-3" id="inventory-report" class="menuItem" @click="toInventoryReport">库存台账上报</el-menu-item>
                  <el-menu-item v-show="getAuthorityMenu('7004')" index="7-4" id="auto-generate" class="menuItem" @click="toAutoGenerate">自动生成订单</el-menu-item>
                 <!-- <el-menu-item index="7-1" id="stock-report" class="menuItem" @click="toStockReport">进货台账上报</el-menu-item>
                  <el-menu-item index="7-2" id="sale-report" class="menuItem" @click="toSaleReport">销货台账上报</el-menu-item>
                  <el-menu-item index="7-3" id="inventory-report" class="menuItem" @click="toInventoryReport">库存台账上报</el-menu-item>
                  <el-menu-item index="7-4" id="auto-generate" class="menuItem" @click="toAutoGenerate">自动生成订单</el-menu-item>-->
                </el-menu-item-group>
              </el-submenu>
              <el-submenu index="8" >
                <template slot="title">
                  <i class="myIcon glyphicon glyphicon-open"></i>
                  <span slot="title">数据上传</span>
                </template>
                <el-menu-item-group>
                  <el-menu-item v-show="getAuthorityMenu('8001')" index="8-1" id="import-data" class="menuItem" @click="toImportData">数据上传</el-menu-item>

                </el-menu-item-group>
              </el-submenu>
            </el-menu>
          </el-row>
        </el-scrollbar>
      </el-aside>

      <el-container>
        <el-header class="topShowHeader">
          <!--<el-link v-model="isCollapse" icon="glyphicon glyphicon-object-align-right" class="handleNav"
                   @click="handleNav"></el-link>-->
          <el-link v-model="isCollapse" icon="glyphicon glyphicon-object-align-left" class="handleNav"
          ></el-link>
          <div class="topBread">
            <el-breadcrumb separator-class="el-icon-arrow-right">
              <el-breadcrumb-item v-if="firstTitle==='首页'" :to="{ path: '/admin/index' }" class="breadLink">
                {{firstTitle}}
              </el-breadcrumb-item>
              <el-breadcrumb-item v-if="firstTitle!=='首页'" class="breadLink">{{firstTitle}}</el-breadcrumb-item>
              <el-breadcrumb-item v-if="secondTitle!==''" class="breadLink">{{secondTitle}}</el-breadcrumb-item>
              <el-breadcrumb-item v-if="thirdTitle!==''" class="breadLink">{{thirdTitle}}</el-breadcrumb-item>
            </el-breadcrumb>
          </div>
          <div v-if="!is_com" class="mdjab">牡丹江市慧台账</div>
          <div class="headerNavRight">
            <el-avatar class="rightIcon" :src="loginUser.avatar ? loginUser.avatar : 'https://gw.alipayobjects.com/zos/rmsportal/BiazfanxmamNRoxxVxka.png'"></el-avatar>
            <el-dropdown class="rightManager" @command="handleCommand">
              <span class="el-dropdown-link">
                <!-- {{loginUser.userName}} -->
                {{ tenantInfo.tenantName }}
                <i class="el-icon-arrow-down el-icon--right"></i>
              </span>
              <el-dropdown-menu class="topShowHeader" slot="dropdown">
                <el-dropdown-item command="storeManager">店铺管理</el-dropdown-item>
                <!--<el-dropdown-item command="pesticideLicense">农药经营许可信息</el-dropdown-item>-->
                <el-dropdown-item command="modifyPass">修改密码</el-dropdown-item>
                <el-dropdown-item command="connectOur">联系我们</el-dropdown-item>
                <el-dropdown-item command="certificateDown">证书下载</el-dropdown-item>
                <el-dropdown-item command="loginOut" divided>安全退出</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </div>
          <el-tabs class="tabsfix" v-model="editableTabsValue" type="card" editable @edit="handleTabsEdit"
                   @tab-click="tabClick" @tab-remove="removeTab">
            <el-tab-pane
              :key="index"
              v-for="(item, index) in editableTabs"
              :label="item.title"
              :name="item.name"
            >
            </el-tab-pane>
          </el-tabs>
        </el-header>
        <el-main class="mainContainer">
          <!--这里的routerView不可放在el-tabs内，否则页面刷新router丢失-->
          <router-view :class="maxHeight?'mainContent':'minContent'" @navChange="navChange" @linkTo="linkTo" v-if="reloadPage" @noAuth="noAuth"></router-view>
          <div class="driverBox">
            <div id="sector-nav-id">
              <el-tooltip content="获取身份证阅读器驱动" placement="top">
                <div class="sector-nav-home" @click="downLoadIdentity">
                  <img class="idCardMainImg" src="/static/imgs/tabbar/sfzFront_white.png" alt="身份证读卡器驱动">
                </div>
              </el-tooltip>
            </div>
            <div id="sector-nav">
              <el-tooltip content="获取打印驱动和预览插件" placement="top">
                <div class="sector-nav-home" @click="downLoadPrint">
                  <i class="el-icon-printer"></i>
                </div>
              </el-tooltip>
            </div>
          </div>

        </el-main>
        <!--<el-footer>Footer</el-footer>-->
      </el-container>
    </el-container>
    <div class="dialogs">
      <my-store-manager ref="myStoreManager" @getTenantInfo="getTenantInfo"></my-store-manager>
      <my-pwd ref="myPwd"></my-pwd>
      <my-contact ref="myContact"></my-contact>
      <my-certificate ref="certificateDown"></my-certificate>
      <PesticideLicense ref="pesticideLicense"></PesticideLicense>
    </div>

  </div>
</template>

<script>
  import MyStoreManager from '../../components/MyStoreManager'
  import MyPwd from '../../components/MyPwd'
  import MyContact from '../../components/MyContact'
  import MyCertificate from '../../components/MyCertificate'
  import PesticideLicense from '../../components/PesticideLicense'

  export default {
    name: 'MainNav',
    components: {
      MyStoreManager,
      MyPwd,
      MyContact,
      MyCertificate,
      PesticideLicense
    },
    provide(){
      return {
        reload:this.reload
      }
    },
    data () {
      return {
        is_com:process.env.VUE_APP_SERVER=='https://nongyao.zftdata.com' || process.env.VUE_APP_SERVER=='https://jxccs.weituodata.com:81'?true:false,//是否是慧台账  false是牡丹江
        //is_com:process.env.VUE_APP_SERVER=='https://nongyao.zftdata.com'?true:false,//是否是慧台账  false是牡丹江
        message: '导航页面',
        reloadPage:true,
        isCollapse: false,
        //circleUrl: 'https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png',
        openeds: ['1', '2', '3', '4', '5','6','7','8'],
        //标签页
        editableTabsValue: '1',
        editableTabs: [],
        tabIndex: '1',

        //面包屑
        firstTitle: '首页',
        secondTitle: '',
        thirdTitle: '',
        loginUser: {},
        tenantInfo: {},
        updateNav: true,
        navMainList: [
          {
            path: 'admin/index',
            name: '/index',
            firstName: '首页',
            secondName: '',
            thirdName: '',
            type: 0,
            tabIndex: 1,
            active: false,
          },
          //进货
          {
            path: 'admin/stockOrders',
            name: '/stockOrders',
            firstName: '进货管理',
            secondName: '进货开单',
            thirdName: '',
            type: 0,
            tabIndex: 2,
            active: false,
          },
          {
            path: 'admin/stockOrderList',
            name: '/stockOrderList',
            firstName: '进货管理',
            secondName: '进货单据',
            thirdName: '',
            type: 0,
            tabIndex: 3,
            active: false,
          },
          {
            path: 'admin/stockOrderReturns',
            name: '/stockOrderReturns',
            firstName: '进货管理',
            secondName: '进货退货',
            thirdName: '',
            type: 0,
            tabIndex: 4,
            active: false,
          },
          {
            path: 'admin/stockOrderReturnList',
            name: '/stockOrderReturnList',
            firstName: '进货管理',
            secondName: '进货退货单据',
            thirdName: '',
            type: 0,
            tabIndex: 5,
            active: false,
          },
          //销货
          {
            path: 'admin/saleOrders',
            name: '/saleOrders',
            firstName: '销货管理',
            secondName: '销货开单',
            thirdName: '',
            type: 0,
            tabIndex: 6,
            active: false,
          },
          {
            path: 'admin/saleOrderList',
            name: '/saleOrderList',
            firstName: '销货管理',
            secondName: '销货单据',
            thirdName: '',
            type: 0,
            tabIndex: 7,
            active: false,
          },
          {
            path: 'admin/saleOrderReturns',
            name: '/saleOrderReturns',
            firstName: '销货管理',
            secondName: '销货退货',
            thirdName: '',
            type: 0,
            tabIndex: 8,
            active: false,
          },
          {
            path: 'admin/saleOrderReturnList',
            name: '/saleOrderReturnList',
            firstName: '销货管理',
            secondName: '销货退货单据',
            thirdName: '',
            type: 0,
            tabIndex: 9,
            active: false,
          },
          //回收处置
          {
            path: 'admin/recycleOrders',
            name: '/recycleOrders',
            firstName: '回收处置',
            secondName: '回收开单',
            thirdName: '',
            type: 0,
            tabIndex: 10,
            active: false,
          },
          {
            path: 'admin/recycleOrderList',
            name: '/recycleOrderList',
            firstName: '回收处置',
            secondName: '回收单据',
            thirdName: '',
            type: 0,
            tabIndex: 11,
            active: false,
          },
          {
            path: 'admin/recycleOrderReturns',
            name: '/recycleOrderReturns',
            firstName: '回收处置',
            secondName: '处置开单',
            thirdName: '',
            type: 0,
            tabIndex: 12,
            active: false,
          },
          {
            path: 'admin/recycleOrderReturnList',
            name: '/recycleOrderReturnList',
            firstName: '回收处置',
            secondName: '处置单据',
            thirdName: '',
            type: 0,
            tabIndex: 13,
            active: false,
          },
          //基础管理
          {
            path: 'admin/goodsManager',
            name: '/goodsManager',
            firstName: '基础管理',
            secondName: '商品管理',
            thirdName: '',
            type: 0,
            tabIndex: 14,
            active: false,
          },
          {
            path: 'admin/goodsManagerEdit',
            name: '/goodsManagerEdit',
            firstName: '基础管理',
            secondName: '商品管理',
            thirdName: '新增商品,编辑商品',
            type: 0,
            tabIndex: 14,
            active: false,
          },

          {
            path: 'admin/clientManager',
            name: '/clientManager',
            firstName: '基础管理',
            secondName: '客户管理',
            thirdName: '',
            type: 0,
            tabIndex: 15,
            active: false,
          },
          {
            path: 'admin/clientManagerEdit',
            name: '/clientManagerEdit',
            firstName: '基础管理',
            secondName: '客户管理',
            thirdName: '新增客户,编辑客户',
            type: 0,
            tabIndex: 15,
            active: false,
          },
          {
            path: 'admin/clientManagerSaleDetail',
            name: '/clientManagerSaleDetail',
            firstName: '基础管理',
            secondName: '客户管理',
            thirdName: '往来明细',
            type: 0,
            tabIndex: 15,
            active: false,
          },

          {
            path: 'admin/supplierManager',
            name: '/supplierManager',
            firstName: '基础管理',
            secondName: '供应商管理',
            thirdName: '',
            type: 0,
            tabIndex: 16,
            active: false,
          },
          {
            path: 'admin/supplierManagerEdit',
            name: '/supplierManagerEdit',
            firstName: '基础管理',
            secondName: '供应商管理',
            thirdName: '新增供应商,编辑供应商',
            type: 0,
            tabIndex: 16,
            active: false,
          },
          {
            path: 'admin/supplierManagerSaleDetail',
            name: '/supplierManagerSaleDetail',
            firstName: '基础管理',
            secondName: '供应商管理',
            thirdName: '往来明细',
            type: 0,
            tabIndex: 16,
            active: false,
          },
           {
            path: 'admin/recipelManager',
            name: '/recipelManager',
            firstName: '基础管理',
            secondName: '处方管理',
            thirdName: '',
            type: 0,
            tabIndex: 17,
            active: false,
          },
          {
            path: 'admin/recipelManagerEdit',
            name: '/recipelManagerEdit',
            firstName: '基础管理',
            secondName: '处方管理',
            thirdName: '新增处方,编辑处方',
            type: 0,
            tabIndex: 17,
            active: false,
          },
          {
            path: 'admin/recipelManagerDetail',
            name: '/recipelManagerDetail',
            firstName: '基础管理',
            secondName: '处方管理',
            thirdName: '处方详情',
            type: 0,
            tabIndex: 17,
            active: false,
          },
          //权限管理
          {
            path: 'admin/userManager',
            name: '/userManager',
            firstName: '系统管理',
            secondName: '店铺缴费',
            thirdName: '',
            type: 0,
            tabIndex: 18,
            active: false,
          },
          {
            path: 'admin/privilegeManager',
            name: '/privilegeManager',
            firstName: '系统管理',
            secondName: '权限设置',
            thirdName: '',
            type: 0,
            tabIndex: 19,
            active: false,
          },
          {
            path: 'admin/privilegeManagerEdit',
            name: '/privilegeManagerEdit',
            firstName: '系统管理',
            secondName: '权限设置',
            thirdName: '新增用户,编辑用户',
            type: 0,
            tabIndex: 19,
            active: false,
          },
          {
            path: 'admin/userRolesManager',
            name: '/userRolesManager',
            firstName: '系统管理',
            secondName: '用户管理',
            thirdName: '',
            type: 0,
            tabIndex: 20,
            active: false,
          },
          {
            path: 'admin/userRolesManagerEdit',
            name: '/userRolesManagerEdit',
            firstName: '系统管理',
            secondName: '用户管理',
            thirdName: '新增用户,编辑用户',
            type: 0,
            tabIndex: 20,
            active: false,
          },
          {
            path: 'admin/roleManager',
            name: '/roleManager',
            firstName: '系统管理',
            secondName: '角色管理',
            thirdName: '',
            type: 0,
            tabIndex: 21,
            active: false,
          },
          {
            path: 'admin/roleManagerEdit',
            name: '/roleManagerEdit',
            firstName: '系统管理',
            secondName: '角色管理',
            thirdName: '新增角色,编辑角色',
            type: 0,
            tabIndex: 21,
            active: false,
          },
          {
            path: 'admin/resourceManager',
            name: '/resourceManager',
            firstName: '系统管理',
            secondName: '资源管理',
            thirdName: '',
            type: 0,
            tabIndex: 22,
            active: false,
          },
          //上报
          {
            path: 'admin/stockReport',
            name: '/stockReport',
            firstName: '台账上报',
            secondName: '进货台账上报',
            thirdName: '',
            type: 0,
            tabIndex: 23,
            active: false,
          },
          {
            path: 'admin/saleReport',
            name: '/saleReport',
            firstName: '台账上报',
            secondName: '销货台账上报',
            thirdName: '',
            type: 0,
            tabIndex: 24,
            active: false,
          },
          {
            path: 'admin/inventoryReport',
            name: '/inventoryReport',
            firstName: '台账上报',
            secondName: '库存台账上报',
            thirdName: '',
            type: 0,
            tabIndex: 25,
            active: false,
          },
          {
            path: 'admin/autoGenerate',
            name: '/autoGenerate',
            firstName: '台账上报',
            secondName: '自动生成订单',
            thirdName: '',
            type: 0,
            tabIndex: 26,
            active: false,
          },
          //上传
          {
            path: 'admin/importData',
            name: '/importData',
            firstName: '数据上传',
            secondName: '数据上传',
            thirdName: '',
            type: 0,
            tabIndex: 27,
            active: false,
          },
        ],
        childFlag: 0,
        activeClass:'',
        authorityFlag:true,
        isHLJ:LocalStorage.get(IS_HLJ),
        maxHeight:false,//是否有最大高度
      }
    },
    watch: {
      //2.公共监听路由
      $route: {

        handler: function (newVal, oldVal) {
          let admin=document.getElementById('admin-index')
          let clientManager=document.getElementById('client-manager')
          let supplierManager=document.getElementById('supplier-manager')
          let goodsManager=document.getElementById('goods-manager')

          if(newVal.path.indexOf('admin/index')!==-1){
            this.activeClass='首页';
          }
          let path=newVal.path.substr(1)
          //权限2：监控跳转的路由是否有权限
          if (!this.getAuthorityRouter(path)) {
            this.noAuth()
            this.authorityFlag=false
          }

          this.checkMaxHeight(newVal.path)
        }
      }
    },
    mounted () {
      let elMenuItem = document.getElementsByClassName('el-menu-item')
      for (let i = 0; i < elMenuItem.length; i++) {
        elMenuItem[i].style.paddingLeft = '0'
      }
      let menuItem = document.getElementsByClassName('menuItem')
      //获取缓存中的标签页
      if (Tool.isNotEmpty(SessionStorage.get(TAB_PANE_LIST))) {
        this.editableTabs = SessionStorage.get(TAB_PANE_LIST)
      }
      if (Tool.isNotEmpty(LocalStorage.get('LOGIN_USER'))) {
        this.loginUser = LocalStorage.get('LOGIN_USER')
      }

      this.getTenantInfo()
      if (this.updateNav && this.authorityFlag) {
        this.updateRouter()
      }
      this.navChange()
      //console.log("是否为黑龙江：",this.isHLJ)
    },
    methods: {
      reload(){
        this.reloadPage=false
        this.$nextTick(function () {
          this.reloadPage=true
        })
      },
      checkMaxHeight(path){
        console.log("当前路由：",path)
        this.maxHeight=true
        let pageList=[
          '/admin/index',

          '/admin/stockOrders',
          '/admin/stockOrderList',
          '/admin/stockOrderReturns',
          '/admin/stockOrderReturnList',

          '/admin/saleOrders',
          '/admin/saleOrderList',
          '/admin/saleOrderReturns',
          '/admin/saleOrderReturnList',

          '/admin/recycleOrders',
          '/admin/recycleOrderList',
          '/admin/recycleOrderReturns',
          '/admin/recycleOrderReturnList',

          '/admin/goodsManager',
          '/admin/clientManager',
          '/admin/supplierManager',
          '/admin/recipelManager',
          '/admin/resourceManager',

          '/admin/userManager',
          '/admin/privilegeManager',
          '/admin/userRolesManager',
          '/admin/roleManager',

          '/admin/stockReport',
          '/admin/saleReport',
          '/admin/inventoryReport',
          '/admin/autoGenerate',
          '/admin/importData',
        ]
        pageList.map(item=>{
          if(item==path){
            this.maxHeight=false
          }
        })

      },
      downLoadPrint() {
        /*
        console.log("打印：",process.env.VUE_APP_SERVER)
        let blob=new Blob();
        blob=new Blob(['/static/package/打印驱动及预览安装包.zip'],{type:'application/zip'});
        console.log("blob: ",blob)
        let fileName=decodeURIComponent('打印驱动及预览安装包.zip');
        let link=document.createElement("a");
        link.style.display="none";
        link.href='http://192.168.1.111:81/static/package/打印驱动及预览安装包.zip';
        link.setAttribute('download',fileName);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        */
        location.href = 'http://nongyao.zftdata.com/tools/打印驱动及预览安装包.zip';
      },
      downLoadIdentity(){
        location.href = 'http://nongyao.zftdata.com/tools/身份证读卡器必装程序.zip';
      },
      getTenantInfo () {
        if (Tool.isNotEmpty(LocalStorage.get(TENANT_INFO))) {
          this.tenantInfo = LocalStorage.get(TENANT_INFO)
          this.$axios.request({
            url: process.env.VUE_APP_SERVER + '/blade-system/tenant/tenantDetail2',
            method: 'GET'
          })
            .then((res) => {
              if (res.status === 200) {
                if (res.data.code == 200) {
                  this.tenantInfo.tenantName = res.data.data.tenantName
                  this.tenantInfo.regionCode=res.data.data.regionCode
                  this.tenantInfo.creditCode=res.data.data.creditCode
                  let regionCode12=this.tenantInfo.regionCode.substr(0,2)
                  let creditCode34=this.tenantInfo.creditCode.substr(2,2)
                  this.tenantInfo.printEnable = res.data.data.printEnable;
                  //console.log("regionCode12",regionCode12,"creditCode34: ",creditCode34)
                  if ((Tool.isNotEmpty(this.tenantInfo.regionCode) && regionCode12 === '13') || (Tool.isNotEmpty(this.tenantInfo.creditCode) && creditCode34 === '13')){
                    this.tenantInfo.hebei=true
                  }else{
                  this.tenantInfo.hebei=false
                  }
                  LocalStorage.set(TENANT_INFO, this.tenantInfo)
                } else {
                  this.$message({
                    type: 'warning',
                    message: res.data.msg,
                    duration: 2000,
                    offset: 110
                  })
                }
              } else {
                this.$message({
                  type: 'warning',
                  message: '网络异常',
                  duration: 2000,
                  offset: 110
                })
              }
            })
        }
      },
      navChange (e) {
        let _this=this
        _this.childFlag = e
        if(_this.childFlag===undefined){
          _this.childFlag=SessionStorage.get(EDIT_FLAG)
        }

        setTimeout(()=>{
          if (Tool.isNotEmpty(SessionStorage.get(TAB_PANE_LIST)) && _this.authorityFlag) {
            _this.editableTabs = SessionStorage.get(TAB_PANE_LIST)
            //console.log('第一次this.editableTabs: ', this.editableTabs)
          }
          if (Tool.isNotEmpty(LocalStorage.get('LOGIN_USER'))) {
            _this.loginUser = LocalStorage.get('LOGIN_USER')
          }
          if(_this.authorityFlag){
            _this.updateRouter()
          }
        },100)

      },
      linkTo(e){
        if(e==='/admin/goodsManager'){
          this.toGoodsManager();
        }
        if(e==='/admin/clientManager'){
          this.toClientManager();
        }
        if(e==='/admin/supplierManager'){
          this.toSupplierManager();
        }
        if(e==='/admin/stockOrders'){
          this.toStockOrder();
        }
        if(e==='/admin/saleOrders'){
          this.toSaleOrder();
        }
        if(e==='/admin/saleOrderList'){
          this.toSaleOrderList()
        }
        if(e==='/admin/recycleOrders'){
          this.toRecycleOrder()
        }
        if(e==='/admin/recycleOrderReturns'){
          this.toRecycleOrderReturn()
        }
      },
      pushTabs (tab) {
        /*if(tab.path===''){

        }*/
        this.editableTabs.push({
          title: tab.secondName===''?tab.firstName:tab.secondName,
          name: tab.tabIndex.toString(),
          type: tab.type,
          active: true,
          content: tab.path
        })
      },

      //更新router
      updateRouter (path) {
        let routerPath = this.$route.path.substr(1)
        if (path) {
          routerPath = path
        }
        for (let i = 0; i < this.navMainList.length; i++) {
          if (this.navMainList[i].path === routerPath) {
            let currentTitle = ''
            currentTitle = this.navMainList[i].firstName
            //首页
            if (routerPath.indexOf('admin/index') !== -1) {
              this.firstTitle = currentTitle
              this.secondTitle = ''
              this.thirdTitle = ''
              let adminIndex = document.getElementById('admin-index')
              Tool.addClass(adminIndex, 'is-active')
              Tool.addClass(adminIndex, 'menuItemActive')
              adminIndex.style.color='rgb(255, 208, 75)'
              this.updateLeftActive(adminIndex)
              let navPane = document.getElementsByClassName('el-tabs__nav')
            }
            //进货
            if (routerPath.indexOf('admin/stockOrders') !== -1) {
              let navIndex = document.getElementById('stock-order')
              this.updateLeftActive(navIndex)
              this.firstTitle = currentTitle
              this.secondTitle = this.navMainList[i].secondName
              this.thirdTitle = ''
            }
            if (routerPath.indexOf('admin/stockOrderList') !== -1) {
              let navIndex = document.getElementById('stock-orderList')
              this.updateLeftActive(navIndex)
              this.firstTitle = currentTitle
              this.secondTitle = this.navMainList[i].secondName
              this.thirdTitle = ''
            }
            if (routerPath.indexOf('admin/stockOrderReturns') !== -1) {
              let navIndex = document.getElementById('stock-order-return')
              this.updateLeftActive(navIndex)
              this.firstTitle = currentTitle
              this.secondTitle = this.navMainList[i].secondName
              this.thirdTitle = ''
            }
            if (routerPath.indexOf('admin/stockOrderReturnList') !== -1) {
              let navIndex = document.getElementById('stock-order-returnList')
              this.updateLeftActive(navIndex)
              this.firstTitle = currentTitle
              this.secondTitle = this.navMainList[i].secondName
              this.thirdTitle = ''
            }
            //销货
            if (routerPath.indexOf('admin/saleOrders') !== -1) {
              let navIndex = document.getElementById('sale-order')
              this.updateLeftActive(navIndex)
              this.firstTitle = currentTitle
              this.secondTitle = this.navMainList[i].secondName
              this.thirdTitle = ''
            }
            if (routerPath.indexOf('admin/saleOrderList') !== -1) {
              let navIndex = document.getElementById('sale-orderList')
              this.updateLeftActive(navIndex)
              this.firstTitle = currentTitle
              this.secondTitle = this.navMainList[i].secondName
              this.thirdTitle = ''
            }
            if (routerPath.indexOf('admin/saleOrderReturns') !== -1) {
              let navIndex = document.getElementById('sale-order-return')
              this.updateLeftActive(navIndex)
              this.firstTitle = currentTitle
              this.secondTitle = this.navMainList[i].secondName
            }
            if (routerPath.indexOf('admin/saleOrderReturnList') !== -1) {
              let navIndex = document.getElementById('sale-order-returnList')
              this.updateLeftActive(navIndex)
              this.firstTitle = currentTitle
              this.secondTitle = this.navMainList[i].secondName
              this.thirdTitle = ''
            }
            //包装回收
            if (routerPath.indexOf('admin/recycleOrders') !== -1) {
              let navIndex = document.getElementById('recycle-order')
              this.updateLeftActive(navIndex)
              this.firstTitle = currentTitle
              this.secondTitle = this.navMainList[i].secondName
              this.thirdTitle = ''
            }
            if (routerPath.indexOf('admin/recycleOrderList') !== -1) {
              let navIndex = document.getElementById('recycle-orderList')
              this.updateLeftActive(navIndex)
              this.firstTitle = currentTitle
              this.secondTitle = this.navMainList[i].secondName
              this.thirdTitle = ''
            }
            if (routerPath.indexOf('admin/recycleOrderReturns') !== -1) {
              let navIndex = document.getElementById('recycle-order-return')
              this.updateLeftActive(navIndex)
              this.firstTitle = currentTitle
              this.secondTitle = this.navMainList[i].secondName
              this.thirdTitle = ''
            }
            if (routerPath.indexOf('admin/recycleOrderReturnList') !== -1) {
              let navIndex = document.getElementById('recycle-order-returnList')
              this.updateLeftActive(navIndex)
              this.firstTitle = currentTitle
              this.secondTitle = this.navMainList[i].secondName
              this.thirdTitle = ''
            }
            //基础管理
            if (routerPath.indexOf('admin/goodsManager') !== -1) {
              let navIndex = document.getElementById('goods-manager')
              this.updateLeftActive(navIndex)
              this.firstTitle = currentTitle
              this.secondTitle = this.navMainList[i].secondName
              this.thirdTitle = ''
            }
            if (routerPath.indexOf('admin/goodsManagerEdit') !== -1) {
              let navIndex = document.getElementById('goods-manager')
              this.updateLeftActive(navIndex)
              this.firstTitle = currentTitle
              this.secondTitle = this.navMainList[i].secondName
              let thirdStr = []
              thirdStr = this.navMainList[i].thirdName.split(',')
              if (this.childFlag === 0) {
                this.thirdTitle = thirdStr[0]
              } else {
                this.thirdTitle = thirdStr[1]
              }
              this.childFlag = 0
            }
            if (routerPath.indexOf('admin/clientManager') !== -1) {
              let navIndex = document.getElementById('client-manager')
              this.updateLeftActive(navIndex)
              this.firstTitle = currentTitle
              this.secondTitle = this.navMainList[i].secondName
              this.thirdTitle = ''
            }
            if (routerPath.indexOf('admin/clientManagerEdit') !== -1) {
              let navIndex = document.getElementById('client-manager')
              this.updateLeftActive(navIndex)
              this.firstTitle = currentTitle
              this.secondTitle = this.navMainList[i].secondName
              let thirdStr = []
              thirdStr = this.navMainList[i].thirdName.split(',')
              if (this.childFlag === 0) {
                this.thirdTitle = thirdStr[0]
              } else {
                this.thirdTitle = thirdStr[1]
              }
              this.childFlag = 0
            }
            if (routerPath.indexOf('admin/clientManagerSaleDetail') !== -1) {
              let navIndex = document.getElementById('client-manager')
              this.updateLeftActive(navIndex)
              this.firstTitle = currentTitle
              this.secondTitle = this.navMainList[i].secondName
              this.thirdTitle = this.navMainList[i].thirdName
            }
            if (routerPath.indexOf('admin/supplierManager') !== -1) {
              let navIndex = document.getElementById('supplier-manager')
              this.updateLeftActive(navIndex)
              this.firstTitle = currentTitle
              this.secondTitle = this.navMainList[i].secondName
              this.thirdTitle = ''
            }
            if (routerPath.indexOf('admin/supplierManagerEdit') !== -1) {
              let navIndex = document.getElementById('supplier-manager')
              this.updateLeftActive(navIndex)
              this.firstTitle = currentTitle
              this.secondTitle = this.navMainList[i].secondName
              this.thirdTitle = this.navMainList[i].thirdName
              let thirdStr = []
              thirdStr = this.navMainList[i].thirdName.split(',')
              //console.log("当前的childFlag：",this.childFlag)
              if (this.childFlag === 0) {
                this.thirdTitle = thirdStr[0]
              } else {
                this.thirdTitle = thirdStr[1]
              }
              this.childFlag = 0
            }
            if (routerPath.indexOf('admin/supplierManagerSaleDetail') !== -1) {
              let navIndex = document.getElementById('supplier-manager')
              this.updateLeftActive(navIndex)
              this.firstTitle = currentTitle
              this.secondTitle = this.navMainList[i].secondName
              this.thirdTitle = this.navMainList[i].thirdName
            }
            if (routerPath.indexOf('admin/recipelManager') !== -1) {
              let navIndex = document.getElementById('recipel-manager')
              this.updateLeftActive(navIndex)
              this.firstTitle = currentTitle
              this.secondTitle = this.navMainList[i].secondName
              this.thirdTitle = ''
            }
            if (routerPath.indexOf('admin/recipelManagerEdit') !== -1) {
              let navIndex = document.getElementById('recipel-manager')
              this.updateLeftActive(navIndex)
              this.firstTitle = currentTitle
              this.secondTitle = this.navMainList[i].secondName
              //this.thirdTitle = this.navMainList[i].thirdName
              let thirdStr = []
              thirdStr = this.navMainList[i].thirdName.split(',')
              if (this.childFlag === 0) {
                this.thirdTitle = thirdStr[0]
              } else {
                this.thirdTitle = thirdStr[1]
              }
              this.childFlag = 0
            }
            if (routerPath.indexOf('admin/recipelManagerSaleDetail') !== -1) {
              let navIndex = document.getElementById('recipel-manager')
              this.updateLeftActive(navIndex)
              this.firstTitle = currentTitle
              this.secondTitle = this.navMainList[i].secondName
              this.thirdTitle = this.navMainList[i].thirdName
            }
            //系统管理
            if (routerPath.indexOf('admin/userManager') !== -1) {
              let navIndex = document.getElementById('user-manager')
              this.updateLeftActive(navIndex)
              this.firstTitle = currentTitle
              this.secondTitle = this.navMainList[i].secondName
              this.thirdTitle = ''
            }
            if (routerPath.indexOf('admin/privilegeManager') !== -1) {
              let navIndex = document.getElementById('privilege-manager')
              this.updateLeftActive(navIndex)
              this.firstTitle = currentTitle
              this.secondTitle = this.navMainList[i].secondName
              this.thirdTitle = ''
            }
            if (routerPath.indexOf('admin/privilegeManagerEdit') !== -1) {
              let navIndex = document.getElementById('privilege-manager')
              this.updateLeftActive(navIndex)
              this.firstTitle = currentTitle
              this.secondTitle = this.navMainList[i].secondName
              let thirdStr = []
              thirdStr = this.navMainList[i].thirdName.split(',')
              if (this.childFlag === 0) {
                this.thirdTitle = thirdStr[0]
              } else {
                this.thirdTitle = thirdStr[1]
              }
              this.childFlag = 0
            }
            if (routerPath.indexOf('admin/userRolesManager') !== -1) {
              let navIndex = document.getElementById('user-role-manager')
              this.updateLeftActive(navIndex)
              this.firstTitle = currentTitle
              this.secondTitle = this.navMainList[i].secondName
              this.thirdTitle = ''
            }
            if (routerPath.indexOf('admin/userRolesManagerEdit') !== -1) {
              let navIndex = document.getElementById('user-role-manager')
              this.updateLeftActive(navIndex)
              this.firstTitle = currentTitle
              this.secondTitle = this.navMainList[i].secondName
              let thirdStr = []
              thirdStr = this.navMainList[i].thirdName.split(',')
              //console.log("当前的childFlag：",this.childFlag)
              if (this.childFlag === 0) {
                this.thirdTitle = thirdStr[0]
              } else {
                this.thirdTitle = thirdStr[1]
              }
              this.childFlag = 0
            }
            if (routerPath.indexOf('admin/roleManager') !== -1) {
              let navIndex = document.getElementById('role-manager')
              this.updateLeftActive(navIndex)
              this.firstTitle = currentTitle
              this.secondTitle = this.navMainList[i].secondName
              this.thirdTitle = ''
            }
            if (routerPath.indexOf('admin/roleManagerEdit') !== -1) {
              let navIndex = document.getElementById('role-manager')
              this.updateLeftActive(navIndex)
              this.firstTitle = currentTitle
              this.secondTitle = this.navMainList[i].secondName
              let thirdStr = []
              thirdStr = this.navMainList[i].thirdName.split(',')
              //console.log("当前的childFlag：",this.childFlag)
              if (this.childFlag === 0) {
                this.thirdTitle = thirdStr[0]
              } else {
                this.thirdTitle = thirdStr[1]
              }
              this.childFlag = 0
            }
            if (routerPath.indexOf('admin/resourceManager') !== -1) {
              let navIndex = document.getElementById('resource-manager')
              this.updateLeftActive(navIndex)
              this.firstTitle = currentTitle
              this.secondTitle = this.navMainList[i].secondName
              this.thirdTitle = ''
            }

            //台账上报
            if (routerPath.indexOf('admin/stockReport') !== -1) {
              let navIndex = document.getElementById('stock-report')
              this.updateLeftActive(navIndex)
              this.firstTitle = currentTitle
              this.secondTitle = this.navMainList[i].secondName
              this.thirdTitle = ''
            }
            if (routerPath.indexOf('admin/saleReport') !== -1) {
              let navIndex = document.getElementById('sale-report')
              this.updateLeftActive(navIndex)
              this.firstTitle = currentTitle
              this.secondTitle = this.navMainList[i].secondName
              this.thirdTitle = ''
            }
            if (routerPath.indexOf('admin/inventoryReport') !== -1) {
              let navIndex = document.getElementById('inventory-report')
              this.updateLeftActive(navIndex)
              this.firstTitle = currentTitle
              this.secondTitle = this.navMainList[i].secondName
              this.thirdTitle = ''
            }
            if (routerPath.indexOf('admin/autoGenerate') !== -1) {
              let navIndex = document.getElementById('auto-generate')
              this.updateLeftActive(navIndex)
              this.firstTitle = currentTitle
              this.secondTitle = this.navMainList[i].secondName
              this.thirdTitle = ''
            }
            //上传
            if (routerPath.indexOf('admin/importData') !== -1) {
              let navIndex = document.getElementById('import-data')
              this.updateLeftActive(navIndex)
              this.firstTitle = currentTitle
              this.secondTitle = this.navMainList[i].secondName
              this.thirdTitle = ''
            }
            let pushFlag = true
            for (let i = 0; i < this.editableTabs.length; i++) {
              if (this.editableTabs[i].content === routerPath
                || (routerPath==='admin/goodsManager' && (this.editableTabs[i].content==='admin/goodsManager' || this.editableTabs[i].content==='admin/goodsManagerEdit'))
                || (routerPath==='admin/goodsManagerEdit' && (this.editableTabs[i].content==='admin/goodsManager' || this.editableTabs[i].content==='admin/goodsManagerEdit'))
                || (routerPath==='admin/roleManagerEdit' && (this.editableTabs[i].content==='admin/roleManager' || this.editableTabs[i].content==='admin/roleManagerEdit'))
                || (routerPath==='admin/userRolesManagerEdit' && (this.editableTabs[i].content==='admin/userRolesManager' || this.editableTabs[i].content==='admin/userRolesManagerEdit'))
                || (routerPath==='admin/privilegeManagerEdit' && (this.editableTabs[i].content==='admin/privilegeManager' || this.editableTabs[i].content==='admin/privilegeManagerEdit'))
                || (routerPath==='admin/clientManager' && (this.editableTabs[i].content==='admin/clientManager' || this.editableTabs[i].content==='admin/clientManagerEdit' || this.editableTabs[i].content==='admin/clientManagerSaleDetail'))
                || (routerPath==='admin/clientManagerEdit' && (this.editableTabs[i].content==='admin/clientManager' || this.editableTabs[i].content==='admin/clientManagerEdit' || this.editableTabs[i].content==='admin/clientManagerSaleDetail'))
                || (routerPath==='admin/clientManagerSaleDetail' && (this.editableTabs[i].content==='admin/clientManager' || this.editableTabs[i].content==='admin/clientManagerEdit' || this.editableTabs[i].content==='admin/clientManagerSaleDetail'))
                || (routerPath==='admin/supplierManager' && (this.editableTabs[i].content==='admin/supplierManager' || this.editableTabs[i].content==='admin/supplierManagerEdit' || this.editableTabs[i].content==='admin/supplierManagerSaleDetail'))
                || (routerPath==='admin/supplierManagerEdit' && (this.editableTabs[i].content==='admin/supplierManager' || this.editableTabs[i].content==='admin/supplierManagerEdit' || this.editableTabs[i].content==='admin/supplierManagerSaleDetail'))
                || (routerPath==='admin/supplierManagerSaleDetail' && (this.editableTabs[i].content==='admin/supplierManager' || this.editableTabs[i].content==='admin/supplierManagerEdit' || this.editableTabs[i].content==='admin/supplierManagerSaleDetail'))
                || (routerPath==='admin/recipelManager' && (this.editableTabs[i].content==='admin/recipelManager' || this.editableTabs[i].content==='admin/recipelManagerEdit' || this.editableTabs[i].content==='admin/recipelManagerDetail'))
                || (routerPath==='admin/recipelManagerEdit' && (this.editableTabs[i].content==='admin/recipelManager' || this.editableTabs[i].content==='admin/recipelManagerEdit' || this.editableTabs[i].content==='admin/recipelManagerDetail'))
                || (routerPath==='admin/recipelManagerDetail' && (this.editableTabs[i].content==='admin/recipelManager' || this.editableTabs[i].content==='admin/recipelManagerEdit' || this.editableTabs[i].content==='admin/recipelManagerDetail'))
                ) {
                pushFlag = false
              }
            }
            //进行push判断
            if (pushFlag) {
              this.pushTabs(this.navMainList[i])
            }
            this.editableTabsValue = this.navMainList[i].tabIndex.toString()
            //console.log("this.editableTabsValue：",this.editableTabsValue);
            this.updateAside()
            SessionStorage.set(TAB_PANE_LIST, this.editableTabs)
          }
        }
        if(this.activeClass!==''){
          this.menuItemActive(this.activeClass)
        }
        this.activeClass=''
      },
      updateAside(){

      },
      //更新左侧导航栏标签状态
      updateLeftActive (item) {
        let menuItem=document.getElementsByClassName('menuItem')
        for(let i=0;i<menuItem.length;i++){
          Tool.removeClass(menuItem[i],'is-active')
          //menuItem[i].style.color(255,255,255)
          Tool.removeClass(menuItem[i], 'menuItemActive')
          menuItem[i].style.color='#ffffff'
        }
        Tool.addClass(item, 'is-active')
        Tool.addClass(item, 'menuItemActive')
        item.style.color='rgb(255, 208, 75)'
      },

      //导航隐藏/显示事件
      handleNav () {
        this.isCollapse = !this.isCollapse
        if (this.isCollapse === false) {
          $('.handleNav')[0].childNodes[0].className = 'glyphicon glyphicon glyphicon-object-align-right'

          $('.my_logo')[0].setAttribute('src', '/static/imgs/login/login_logo_white.png')
          $('.my_logo')[0].style.width = '100px'
          $('.el-aside')[0].style.width = '300px'
          $('.my_log_desc')[0].style.display = 'block'
        }
        if (this.isCollapse === true) {
          $('.handleNav')[0].childNodes[0].className = 'glyphicon glyphicon glyphicon-object-align-left'
          $('.my_logo')[0].setAttribute('src', '/static/imgs/login/login_logo_single.png')
          $('.my_logo')[0].style.width = '40px'
          $('.my_log_desc')[0].style.display = 'none'
          let elMenus = $('.el-submenu .el-menu-item')
          for (let i = 0; i < elMenus.length; i++) {
            elMenus[i].style.borderRight = 'solid 1px #e6e6e6 !import'
          }

        }
      }
      ,
      handleCommand (command) {
        if (command === 'storeManager') {
          this.toMyStore()
        }
        if (command === 'pesticideLicense'){
          this.toPesticideLicense()
        }
        if (command === 'modifyPass') {
          this.toMyPass()
        }
        if (command === 'connectOur') {
          this.toContactUs()
        }
        if(command==='certificateDown'){
          this.toCertificateDown();
        }
        if (command === 'loginOut') {
          this.loginOut()
        }
      },
      noAuth(){
        this.$toast.showAuth("当前登录用户没有访问权限，请重新登录！","center")
        this.authorityFlag=false
        this.$router.push('/admin/index')
      },
      loginOut () {
        SessionStorage.clearAll();

        LocalStorage.remove(TOKEN)
        LocalStorage.remove(TENANT_INFO)
        LocalStorage.remove(LOGIN_USER)
        LocalStorage.remove(IS_LOG)
        LocalStorage.remove(IS_EXISTS_SHOPINFO)
        LocalStorage.remove(IS_HLJ)
        LocalStorage.remove(IS_DN)
        SessionStorage.remove(CODE_URL_LIST)

        SessionStorage.remove(SALE_CART)
        SessionStorage.remove(SALE_RECIPE_DEAL_GOODS_DEL)
        SessionStorage.remove(SALE_RECIPE_DEAL_GOODS)
        SessionStorage.remove(SALE_RECIPE_DEAL_CART)
        SessionStorage.remove(SALE_RECIPE_CURRENT)

        SessionStorage.remove(HLJ_MODE)
        SessionStorage.remove(CODE_URL_LIST)

        SessionStorage.remove(SALE_RETURN_CART)
        SessionStorage.remove(CODE_URL_RETURN_LIST)
        SessionStorage.remove(HLJ_RETURN_MODE)
        //let socket = io.connect('http://localhost:5000',{'timeout': 30000,'reconnectionDelayMax':1000,'reconnectionDelay':500});
        if(this.is_com){
          this.$router.push('/login');
        }else{
          this.$router.push('/mudanjianglogin');
        }

      },
      //标签页
      handleTabsEdit (targetName, action) {
        if (action === 'add') {
          let newTabName = ++this.tabIndex + ''
          this.editableTabs.push({
            title: 'New Tab',
            name: newTabName,
            content: 'New Tab content'
          })
          this.editableTabsValue = newTabName
        }
        let nextTaber = {}
        if (action === 'remove') {

          let tabs = this.editableTabs
          let indexPage = '首页'
          let stockPage = '进货管理'
          let salePage = '销货管理'
          let recyclePage = '回收处置'
          let basePage = '基础管理'
          let myPage = '系统管理'
          //，不可在页面初始化时，更新这个value，当操作删除时作为依据
          if (tabs.length <= 1) {
            this.editableTabsValue = this.editableTabs[this.tabIndex - 1].name
          }
          let activeName = this.editableTabsValue

          if (tabs.length > 1) {
            if (activeName === targetName) {
              tabs.forEach((tab, index) => {
                if (tab.name === targetName) {
                  let nextTab = tabs[index + 1] || tabs[index - 1]
                  //console.log("----------tab: ",tab,"targetName: ",targetName,"tabs[index + 1]: ",tabs[index + 1],"tabs[index - 1]: ",tabs[index - 1])
                  this.removeSession(tab)
                  nextTaber = nextTab
                  if (nextTab) {
                    activeName = nextTab.name
                  }

                  if (nextTaber.title.indexOf('首页') !== -1) {
                    this.firstTitle = indexPage
                    this.secondTitle = ''
                  }
                  if (nextTaber.title.indexOf('进货') !== -1) {
                    this.firstTitle = stockPage
                    this.secondTitle = nextTaber.title
                  }
                  if (nextTaber.title.indexOf('销货') !== -1) {
                    this.firstTitle = salePage
                    this.secondTitle = nextTaber.title
                  }
                  if (nextTaber.title.indexOf('包装') !== -1) {
                    this.firstTitle = recyclePage
                    this.secondTitle = nextTaber.title
                  }
                  if (nextTaber.title.indexOf('基础') !== -1) {
                    this.firstTitle = basePage
                    this.secondTitle = nextTaber.title
                  }
                  if (nextTaber.title.indexOf('用户') !== -1
                    || nextTaber.title.indexOf('角色') !== -1
                    || nextTaber.title.indexOf('资源') !== -1
                    || nextTaber.title.indexOf('权限') !== -1
                    || nextTaber.title.indexOf('店铺缴费') !== -1) {
                    this.firstTitle = myPage
                    this.secondTitle = nextTaber.title
                  }
                  this.menuItemActive(nextTab.title)
                  this.$router.push('/' + nextTaber.content)
                }
              })
            }else{
              for(let i=0;i<this.editableTabs.length;i++){
                if(this.editableTabs[i].name===targetName){
                  this.removeSession(this.editableTabs[i]);
                }
              }
            }
            this.editableTabsValue = activeName
            this.editableTabs = tabs.filter(tab => tab.name !== targetName)

          }
          SessionStorage.set(TAB_PANE_LIST, this.editableTabs)
        }
      },
      /*移除缓存*/
      removeSession(item){
        if(item.content==='admin/stockOrders'){
          SessionStorage.remove(STOCK_CART)
        }
        if(item.content==='admin/stockOrderReturns'){
          SessionStorage.remove(STOCK_RETURN_CART)
        }
        if(item.content==='admin/saleOrders'){
          SessionStorage.remove(SALE_CART)
          SessionStorage.remove(SALE_RECIPE_DEAL_GOODS_DEL)
          SessionStorage.remove(SALE_RECIPE_DEAL_GOODS)
          SessionStorage.remove(SALE_RECIPE_DEAL_CART)
          SessionStorage.remove(CODE_URL_LIST)
          SessionStorage.remove(HLJ_MODE)
          SessionStorage.remove(SALE_RECIPE_CURRENT)
          SessionStorage.remove(SALE_RECIPE_TYPE)

        }
        if(item.content==='admin/saleOrderReturns'){
          SessionStorage.remove(SALE_RETURN_CART)
          SessionStorage.remove(CODE_URL_RETURN_LIST)
          SessionStorage.remove(HLJ_RETURN_MODE)
        }
        if(item.content==='admin/recycleOrders'){
          SessionStorage.remove(RECYCLE_CART)
        }
        if(item.content==='admin/recycleOrderReturns'){
          SessionStorage.remove(RECYCLE_RETURN_CART)
        }
      },

      /*标签页点击*/
      tabClick (tab, event) {
        if (tab.label === '首页') {
          this.$router.push('/admin/index')
          this.firstTitle = tab.label
          this.secondTitle = ''
        }
        //进货
        if (tab.label === '进货开单') {
          this.$router.push('/admin/stockOrders')
          this.firstTitle = '进货管理'
          this.secondTitle = tab.label
        }
        if (tab.label === '进货单据') {
          this.$router.push('/admin/stockOrderList')
          this.firstTitle = '进货管理'
          this.secondTitle = tab.label
        }
        if (tab.label === '进货退货') {
          this.$router.push('/admin/stockOrderReturns')
          this.firstTitle = '进货管理'
          this.secondTitle = tab.label
        }
        if (tab.label === '进货退货单据') {
          this.$router.push('/admin/stockOrderReturnList')
          this.firstTitle = '进货管理'
          this.secondTitle = tab.label
        }
        //销货
        if (tab.label === '销货开单') {
          this.$router.push('/admin/saleOrders')
          this.firstTitle = '销货管理'
          this.secondTitle = tab.label
        }
        if (tab.label === '销货单据') {
          this.$router.push('/admin/saleOrderList')
          this.firstTitle = '销货管理'
          this.secondTitle = tab.label
        }
        if (tab.label === '销货退货') {
          this.$router.push('/admin/saleOrderReturns')
          this.firstTitle = '销货管理'
          this.secondTitle = tab.label
        }
        if (tab.label === '销货退货单据') {
          this.$router.push('/admin/saleOrderReturnList')
          this.firstTitle = '销货管理'
          this.secondTitle = tab.label
        }
        //包装回收
        if (tab.label === '回收开单') {
          this.$router.push('/admin/recycleOrders')
          this.firstTitle = '回收处置'
          this.secondTitle = tab.label
        }
        if (tab.label === '回收单据') {
          this.$router.push('/admin/recycleOrderList')
          this.firstTitle = '回收处置'
          this.secondTitle = tab.label
        }
        if (tab.label === '处置开单') {
          this.$router.push('/admin/recycleOrderReturns')
          this.firstTitle = '回收处置'
          this.secondTitle = tab.label
        }
        if (tab.label === '处置单据') {
          this.$router.push('/admin/recycleOrderReturnList')
          this.firstTitle = '回收处置'
          this.secondTitle = tab.label
        }
        //基础管理
        if (tab.label === '商品管理') {
          this.$router.push('/admin/goodsManager')
          this.firstTitle = '基础管理'
          this.secondTitle = tab.label
        }
        if (tab.label === '客户管理') {
          this.$router.push('/admin/clientManager')
          this.firstTitle = '基础管理'
          this.secondTitle = tab.label
        }
        if (tab.label === '供应商管理') {
          this.$router.push('/admin/supplierManager')
          this.firstTitle = '基础管理'
          this.secondTitle = tab.label
        }
        if (tab.label === '处方管理') {
          this.$router.push('/admin/recipelManager')
          this.firstTitle = '基础管理'
          this.secondTitle = tab.label
        }
        //个人中心
        if (tab.label === '店铺缴费') {
          this.$router.push('/admin/userManager')
          this.firstTitle = '系统管理'
          this.secondTitle = tab.label
        }
        if (tab.label === '权限设置') {
          this.$router.push('/admin/privilegeManager')
          this.firstTitle = '系统管理'
          this.secondTitle = tab.label
        }
        if (tab.label === '用户管理') {
          this.$router.push('/admin/userRolesManager')
          this.firstTitle = '系统管理'
          this.secondTitle = tab.label
        }
        if (tab.label === '角色管理') {
          this.$router.push('/admin/roleManager')
          this.firstTitle = '系统管理'
          this.secondTitle = tab.label
        }
        if (tab.label === '资源管理') {
          this.$router.push('/admin/resourceManager')
          this.firstTitle = '系统管理'
          this.secondTitle = tab.label
        }
        //上报
        if (tab.label === '进货台账上报') {
          this.$router.push('/admin/stockReport')
          this.firstTitle = '台账上报'
          this.secondTitle = tab.label
        }
        if (tab.label === '销货台账上报') {
          this.$router.push('/admin/saleReport')
          this.firstTitle = '台账上报'
          this.secondTitle = tab.label
        }
        if (tab.label === '库存台账上报') {
          this.$router.push('/admin/inventoryReport')
          this.firstTitle = '台账上报'
          this.secondTitle = tab.label
        }
        if (tab.label === '自动生成订单') {
          this.$router.push('/admin/autoGenerate')
          this.firstTitle = '台账上报'
          this.secondTitle = tab.label
        }
        if (tab.label === '数据上传') {
          this.$router.push('/admin/importData')
          this.firstTitle = '数据上传'
          this.secondTitle = tab.label
        }
        //console.log("点击后的",tab,"this.tabIndex: ",this.tabIndex,"this.editableTabs: ",this.editableTabs,"this.editableTabsValue: ",this.editableTabsValue);
        this.menuItemActive(tab.label)
      },
      /*标签页删除*/
      removeTab(targetName) {
        let tabs = this.editableTabs;
        let activeName = this.editableTabsValue;
        if(this.editableTabs>1){
          if (activeName === targetName) {
            tabs.forEach((tab, index) => {
              if (tab.name === targetName) {
                let nextTab = tabs[index + 1] || tabs[index - 1];
                if (nextTab) {
                  activeName = nextTab.name;
                }
              }
            });
          }

          this.editableTabsValue = activeName;
          this.editableTabs = tabs.filter(tab => tab.name !== targetName);
        }

      },
      /*更新导航栏样式*/
      menuItemActive (labelName) {
        let adminIndex = document.getElementById('admin-index')
        Tool.removeClass(adminIndex, 'menuItemActive')
        adminIndex.firstChild.style.color = '#909399'
        let menuItem = document.getElementsByClassName('menuItem')
        //console.log("当前的labeName：",labelName,"menuItem: ",menuItem[0]);
        for (let i = 0; i < menuItem.length; i++) {
          //Tool.removeClass(menuItem[i], 'is-active')
          menuItem[i].style.color = '#ffffff '
          if (labelName.trim() === menuItem[i].innerHTML.trim()) {
            menuItem[i].style.color = 'rgb(255, 208, 75)'
          }
        }

      },

      /*跳转页面*/
      //进货
      toStockOrder () {
        this.$router.push('/admin/stockOrders')
        let tabName = '进货开单'
        this.menuItemActive(tabName)
        this.updateRouter();
      },

      toStockOrderList () {
        this.$router.push('/admin/stockOrderList')
        let tabName = '进货单据'
        this.menuItemActive(tabName)
        this.updateRouter();
      },

      toStockOrderReturn () {
        this.$router.push('/admin/stockOrderReturns')
        let tabName = '进货退货'
        this.menuItemActive(tabName)
        this.updateRouter();
      },

      toStockOrderReturnList () {
        this.$router.push('/admin/stockOrderReturnList')
        let tabName = '进货退货单据'
        this.menuItemActive(tabName)
        this.updateRouter();
      },

      //销货
      toSaleOrder () {
        this.$router.push('/admin/saleOrders')
        let tabName = '销货开单'
        this.menuItemActive(tabName)
        this.updateRouter();
      },

      toSaleOrderList () {
        this.$router.push('/admin/saleOrderList')
        let tabName = '销货单据'
        this.menuItemActive(tabName)
        this.updateRouter();
      },

      toSaleOrderReturn () {
        this.$router.push('/admin/saleOrderReturns')
        let tabName = '销货退货'
        this.menuItemActive(tabName)
        this.updateRouter();
      },

      toSaleOrderReturnList () {
        this.$router.push('/admin/saleOrderReturnList')
        let tabName = '销货退货单据'
        this.menuItemActive(tabName)
        this.updateRouter();
      },

      //包装回收
      toRecycleOrder () {
        this.$router.push('/admin/recycleOrders')
        let tabName = '回收开单'
        this.menuItemActive(tabName)
        this.updateRouter();
      },

      toRecycleOrderList () {
        this.$router.push('/admin/recycleOrderList')
        let tabName = '回收单据'
        this.menuItemActive(tabName)
        this.updateRouter();
      },

      toRecycleOrderReturn () {
        this.$router.push('/admin/recycleOrderReturns')
        let tabName = '处置开单'
        this.menuItemActive(tabName)
        this.updateRouter();
      },

      toRecycleOrderReturnList () {
        this.$router.push('/admin/recycleOrderReturnList')
        let tabName = '处置单据'
        this.menuItemActive(tabName)
        this.updateRouter();
      },

      //基础管理
      toGoodsManager (flag) {
        this.$router.push('/admin/goodsManager')
        let tabName = '商品管理'
        this.menuItemActive(tabName)
        this.updateRouter();
      },

      toClientManager () {
        this.$router.push('/admin/clientManager')
        let tabName = '客户管理'
        this.menuItemActive(tabName)
        this.updateRouter();
      },

      toSupplierManager () {
        this.$router.push('/admin/supplierManager')
        let tabName = '供应商管理'
        this.menuItemActive(tabName)
        this.updateRouter();
      },
      toRecipelManager () {
        this.$router.push('/admin/recipelManager')
        let tabName = '处方管理'
        this.menuItemActive(tabName)
        this.updateRouter();
      },
      toUserManager(){
        this.$router.push('/admin/userManager')
        let tabName = '店铺缴费'
        this.menuItemActive(tabName)
        this.updateRouter();
      },
      toPrivilegeManager(){
        this.$router.push('/admin/privilegeManager')
        let tabName = '权限设置'
        this.menuItemActive(tabName)
        this.updateRouter();
      },
      toUserRoleManager(){
        this.$router.push('/admin/userRolesManager')
        let tabName = '用户管理'
        this.menuItemActive(tabName)
        this.updateRouter();
      },
      toRoleManager(){
        this.$router.push('/admin/roleManager')
        let tabName = '角色管理'
        this.menuItemActive(tabName)
        this.updateRouter();
      },
      toResourceManager(){
        this.$router.push('/admin/resourceManager')
        let tabName = '资源管理'
        this.menuItemActive(tabName)
        this.updateRouter();
      },

      //上报
      toStockReport(){
        this.$router.push('/admin/stockReport')
        let tabName = '进货台账上报'
        this.menuItemActive(tabName)
        this.updateRouter();
      },
      toSaleReport(){
        this.$router.push('/admin/saleReport')
        let tabName = '销货台账上报'
        this.menuItemActive(tabName)
        this.updateRouter();
      },
      toInventoryReport(){
        this.$router.push('/admin/inventoryReport')
        let tabName = '库存台账上报'
        this.menuItemActive(tabName)
        this.updateRouter();
      },
      toAutoGenerate(){
        this.$router.push('/admin/autoGenerate')
        let tabName = '自动生成订单'
        this.menuItemActive(tabName)
        this.updateRouter();
      },
      //上传
      toImportData() {
        this.$router.push('/admin/importData')
        let tabName = '数据上传'
        this.menuItemActive(tabName)
        this.updateRouter();
      },
      //首页
      toAdminIndex () {
        this.$router.push('/admin/index')
        let tabName = '首页'
        this.menuItemActive(tabName)
        this.updateRouter();
      },

      //个人中心
      toMyStore () {
        this.$refs.myStoreManager.openMyStore()
      },
      toPesticideLicense(){
        this.$refs.pesticideLicense.openDialog()
      },
      toMyPass () {
        this.$refs.myPwd.openMyPwd()
      },

      toContactUs () {
        this.$refs.myContact.openMyContact()
      },
      toCertificateDown(){
        this.$refs.certificateDown.openMyCertificate()
      },
      //根据路由判断是否有权限进入菜单
      getAuthorityRouter(router) {

        //获取登录用户的权限对象列表
        let resources = Tool.getUserPrivilege().menuList;
        if (Tool.isEmpty(resources)) {
          return false;
        }else{
          for (let i = 0; i < resources.length; i++) {
            if (router==resources[i].page) {
              return true;
            }
          }
        }
        return false
      },
      getAuthorityMenu(id){
        return Tool.authorityUserResources(id)
      }
    }
  }
</script>

<style scoped>
  .topShowAside {
    z-index: 3000;
    height: 100vh;
    overflow-x: hidden;
    overflow-y: hidden;
    background-color: #211515;
  }

  .asideBar {
    overflow-x: hidden;
    overflow-y: hidden;
    height: 100%;
    z-index: 3000;
    margin-top: 105px;
    padding-bottom: 20px;
  }

  ::v-deep .el-scrollbar__bar .is-vertical {
    height: 50%;
  }

  ::v-deep .el-scrollbar__wrap {
    overflow-x: hidden;
    overflow-y: scroll;
    height: 100%;
    padding-bottom: 80px;
  }

  .topShowHeader {
    z-index: 3000 !important;
    position: relative;
  }
  .mdjab{
    position: absolute;
    top: 0px;
    left: 45%;
    font-size: 22px;
  }
  .el-header, .el-footer {
    background-color: #e52a33;
    color: #ffffff;
    text-align: center;
    line-height: 60px;
    position: fixed;
    width: 90%;
    z-index: 999;
  }

  .el-aside {
    color: #ffffff;
    text-align: center;
    line-height: 200px;
    width: 200px !important;
  }
  .mainContainer{
    overflow-y: hidden;
    height: calc(100vh);
  }
  .el-main {
    background-color: #E9EEF3;
    color: #333;
    text-align: center;
    padding: 0;
    overflow-x: hidden;
  }

  body > .el-container {
    margin-bottom: 40px;
  }

  .el-container:nth-child(5) .el-aside,
  .el-container:nth-child(6) .el-aside {
    line-height: 260px;
  }

  .el-container:nth-child(7) .el-aside {
    line-height: 320px;
  }

  /*导航*/
  .el-menu {
    /*min-height: 885px;*/
    margin-bottom: 20px;
    border-right: unset !important;
  }

  .el-menu-vertical-demo:not(.el-menu--collapse) {
    /*width: 200px;
    min-height: 400px;*/
  }

  .my_logo_show {
    line-height: 70px;
    position: fixed;
    left: 40px;
    z-index: 3001;
    background-color: #211515;
    /*border-right: solid 1px #e6e6e6;*/
    /*transition:all 3s;*/
  }

  .my_logo {
    width: 100px;
  }

  .my_log_desc {
    font-size: 20px;
    margin-top: 0;
    margin-bottom: 0;
    font-weight: 700;
  }

  /*导航关闭开启*/
  .handleNav {
    float: left;
    margin-top: 15px;
    margin-left: 10px;
    color: #ffffff !important;
    text-decoration: none !important;
    background-color: unset;
    border-color: unset !important;
    border: unset !important;
    font-size: 24px;
  }

  .handleNav:hover, .handleNav:after {
    background-color: unset;
    background: unset;
    border-color: unset !important;
    border: unset !important;
    text-decoration: unset;
    color: rgb(255, 208, 75) !important;
    text-decoration: none !important;
  }

  /*左侧导航图标样式*/
  .el-submenu [class^=el-icon-] {
    margin-right: 0;
    float: left;
    margin-left: 10px;
    margin-top: 20px;
  }

  .el-submenu span {
    float: left;
    margin-left: 10px;
  }

  ::v-deep .el-menu-item {
    /*width: 200px ;*/
    padding: 0 10px;
  }

  ::v-deep .el-menu-item-group__title {
    padding: 0 !important;
  }

  ::v-deep .el-submenu__icon-arrow {
    margin-right: 20px;
  }

  .el-menu-item-group .el-menu-item {
    padding-left: 70px !important;
  }

  ::v-deep .el-submenu__title {
    margin-left: 10px;
  }
  .menuItem{
    color: rgb(255,255,255);
  }
  .menuItemActive {
    color: rgb(255, 208, 75);
  }

  /*首页样式*/
  .mainPage {

  }

  .mainPage .el-icon-s-home {
    float: left;
    margin-left: 40px;
    margin-top: 20px;
  }

  .mainPage span {
    float: left;
    margin-left: 10px;
  }

  .el-submenu .el-menu li {
    text-align: left;
  }

  .el-submenu .el-menu li:last-child {
    /*margin-bottom: -30px;*/
  }

  /*左侧栏箭头样式*/


  /*头部样式*/
  .el-header {
    padding: 0;
  }

  /*头部右侧栏样式*/
  .headerNavRight {
    position: fixed;
    right: 0;
    padding-right: 40px;
    color: #ffffff;
    font-size: 18px;

  }

  .rightIcon {
    margin-top: 9px;
    margin-right: 10px;
  }

  .rightManager {
    color: #ffffff;
    top: -15px;
    font-size: 16px;
  }

  /*面包屑导航*/
  .topBread {
    color: #ffffff;
    display: inline-block;
    float: left;
    margin-top: 20px;
    margin-left: 20px;
  }

  .el-breadcrumb {
    font-size: 16px;
  }

 
  .breadLink ::v-deep .el-breadcrumb__inner {
    color: #ffffff !important;
  }

  .breadLink ::v-deep .el-breadcrumb__inner:hover {
    color: rgb(255, 208, 75) !important;
    cursor: pointer !important;
  }

  ::v-deep .el-breadcrumb__separator {
    color: rgb(255, 208, 75) !important;
    margin: 0 6px !important;
    font-weight: 900;
  }

  /*头部下拉菜单*/
  .el-dropdown-menu .el-dropdown-menu__item:hover {
    color: #e92322;
    background: rgba(159, 2, 1, .2);
  }

  /*标签页*/
  .tabsfix ::v-deep .el-tabs__nav-scroll {
    background: #ffffff;
    padding: 5px;
  }

  .tabsfix ::v-deep .el-tabs--card > .el-tabs__header .el-tabs__nav {
    border: unset;
    width: unset;
  }

  .tabsfix ::v-deep .el-tabs__header {
    margin: 0 !important;
  }

  .tabsfix ::v-deep .el-tabs__new-tab {
    display: none !important;
  }

  .tabsfix ::v-deep .el-tabs__item {
    height: 30px;
    line-height: 30px;
    margin-right: 5px;
    border: 1px solid #f58500 !important;
  }
  .tabsfix ::v-deep .el-tabs__item:focus{
    border: unset !important;
    border-radius: unset !important;
    box-shadow:unset !important;
  }
  .tabsfix ::v-deep .el-tabs__item:hover {
    color: #e92322;
  }

  .tabsfix ::v-deep .el-tabs__item.is-active {
    background-color: #f58500 !important;
    color: white;
  }
  ::v-deep .el-tabs--card>.el-tabs__header .el-tabs__nav{
    border: unset ;
  }
  .tabsfix {
    margin-top: 60px;
    line-height: 30px;
  }

  .tabsfix ::v-deep .el-tabs__nav-wrap {
    background: #e9eef3;
  }

  .tabsfix ::v-deep .el-tabs__nav-prev:hover, ::v-deep .el-tabs__nav-next:hover {
    background: rgb(255, 208, 75);
    color: #e92322;
  }

  .tabsfix ::v-deep .el-tabs__nav-prev {
    padding-right: 7px;
  }

  .tabsfix ::v-deep .el-tabs__nav-next {
    position: fixed;
    right: 0;
    z-index: 999;
    top: 60px;
    background: #e9eef3;
    padding-left: 7px;
  }

  /*内容区域*/
  .mainContent {
    background: #ffffff;
    margin: 110px 10px 50px 10px;
    /*height: calc(100vh - 30px);*/
    height: 100%;
  }
  .minContent{
    background: #ffffff;
    margin: 110px 10px 10px 10px;
    height: calc(100% - 90px);
    overflow-y: auto;
  }
  .el-dropdown-link {
    height: 60px;
    display: inline-block;
  }
  .driverBox{
    position: fixed;
    right: 60px;
    bottom: 32px;
    margin: auto;
    z-index: 10;
  }
  * body #sector-nav {
    width: 40px;
    height: 40px;
  }
  * body #sector-nav-id{
    width: 40px;
    height: 40px;
    margin-bottom: 3px;
  }
  #sector-nav-id{

  }
  .sector-nav-home {
    color: #ffffff;
    background: #e92322;
    position: absolute;
    border: 2px solid #f6eea3;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    transition: 1s;
    z-index: 1;
    font-size: 22px;
    cursor: pointer;
  }
  .sector-nav-home:focus,.sector-nav-home:hover{
    transition: 1s;
    transform: rotate(-360deg) scale(1.2);
  }
  #sector-nav::after{
    content:"";
    width:10px;
    height: 10px;
    border-radius: 50%;
    background: #e92322;
    border: 1px solid #f6e4a3;
    bottom: -15px;
    right: 14px;
    position: absolute;
  }
  .sector-nav-home i{
    margin-top: 8px;
  }
  .idCardMainImg{
    width: 28px;
  }
  .myIcon{
    float: left;
    margin-left:10px;
    margin-top:20px;
  }
  .coreBody{
    overflow-y: hidden;
  }

</style>
