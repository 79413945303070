<template>
  <div>
    <el-dialog width="65%" @close="dialogClose"
               :visible.sync="sureDialogVisible"
               class="dialogDetail"
               :close-on-click-modal="false">
      <div slot="title" class="header-title">
        <div class="title">{{type===3?'进货退货开单':'进货开单'}} - 确认订单</div>
      </div>
      <div class="orderNav">
        <div class="navLeft">
          <el-tag class="showCheck">当前供应商
            <span v-if="checkSupplierIds.length>0">{{strSuppliers}}</span></el-tag>
          <el-tag class="showCheck" type="info">购物车商品数量：
            <span class="cartNum">{{goodsCart.num}}</span></el-tag>
        </div>
        <div class="navRight">
          <el-checkbox v-if="type!==3" v-model="isPrintBig" @change="printBigChange" style="float: left; margin-right: 20px; margin-top: 10px;">
            打印大票
          </el-checkbox>
          <el-checkbox v-if="type!==3" v-model="isPrint" @change="printSmallChange" style="float: left; margin-right: 20px; margin-top: 10px;">
            打印小票
          </el-checkbox>
          <el-button v-show="getAuthorityMenu('200103')" v-if="type!==3" class="searchBtn" type="danger" @click="save">确认开单</el-button>
          <el-button v-show="getAuthorityMenu('200302')" v-if="type===3" class="searchBtn" type="danger" @click="save">确认退货开单</el-button>
          <!--<el-button class="printOrder" type="warning" icon="el-icon-s-order" @click="printOrder()">打印单据</el-button>-->
        </div>

      </div>

      <div class="dialogBody">

        <div class="mainForm">
          <el-table
            ref="multipleTable"
            tooltip-effect="dark"
            :data="goodsCart.details"
            border
            height="400px"
            highlight-current-row
            class="previous-row"
            @current-change="handleCurrentChange"
            :header-cell-style="{backgroundImage:'linear-gradient(#f9f9f9,#f9f9f9)',color:'#909399',textAlign:'center'}"
          >
            <el-table-column type="index" label="序号" class="indexCenter" width="50" fixed></el-table-column>
            <el-table-column property="goodsName" label="商品名称" width="160" fixed></el-table-column>
            <el-table-column property="packingSpecification" label="包装规格"></el-table-column>

            <el-table-column v-if="type!==3" property="packageLevel" label="选择包装" width="110" sortable>
              <template slot-scope="scope">
                <el-tag :type="scope.row.buyPackageLevel === 2 ? 'danger':'primary'" class="showCenter">
                  按{{scope.row.buyPackageLevel === 2?scope.row.bigUnitName:scope.row.middleUnitName}}进货
                </el-tag>
              </template>
            </el-table-column>
            <el-table-column v-if="type===3" property="packageLevel" label="退货包装" width="110" sortable>
              <template slot-scope="scope">
                <el-tag :type="scope.row.buyPackageLevel === 2 ? 'danger':'primary'" class="showCenter">
                  按{{scope.row.buyPackageLevel === 2?scope.row.bigUnitName:scope.row.middleUnitName}}进货
                </el-tag>
              </template>
            </el-table-column>

            <el-table-column v-if="type!==3" property="goodsPrice" label="商品单价" width="110" sortable>
              <template slot-scope="scope">
                <el-tag :type="scope.row.goodsPrice <= 0 ? 'warning':'danger'" class="showCenter">
                  ￥ {{scope.row.goodsPrice}}
                </el-tag>
              </template>
            </el-table-column>
            <el-table-column v-if="type===3" property="goodsPrice" label="退货单价" width="110" sortable>
              <template slot-scope="scope">
                <el-tag :type="scope.row.goodsPrice <= 0 ? 'warning':'danger'" class="showCenter">
                  ￥ {{scope.row.goodsPrice}}
                </el-tag>
              </template>
            </el-table-column>

            <el-table-column property="goodsNumber" label="商品数量" width="110" sortable>
              <template slot-scope="scope">
                <el-tag :type="scope.row.buyPackageLevel===1 ? 'primary':'danger'" class="showCenter"
                        id="goodsNumber">{{scope.row.goodsNumber}}&nbsp;{{scope.row.buyPackageLevel===1?scope.row.middleUnitName:scope.row.bigUnitName}}
                </el-tag>
              </template>
            </el-table-column>
            <el-table-column property="totalPrice" label="小计" width="120px">
              <template slot-scope="scope">
                <el-tag type="warning" class="showCenter">
                  {{scope.row.totalPrice}}&nbsp;元
                </el-tag>
              </template>
            </el-table-column>
          </el-table>
        </div>

        <el-row :gutter="10" class="rowForm">
          <el-col :span="7">
            <div class="bottomMoney">
              <div >
                <div class="totalMoney">
                  <div class="label">开单时间：</div>
                  <div class="timeCheck">
                    <el-date-picker class="timePickers"
                                    v-model="createTime"
                                    type="datetime"
                                    placeholder="选择日期时间"
                                    align="right"
                                    :clearable="false"
                                    :editable="false"
                                    @change="timePickerChange"
                                    value-format="yyyy-MM-dd HH:mm:ss"
                                    :picker-options="pickerOptions">
                    </el-date-picker>
                  </div>
                </div>
              </div>
              <div v-if="type!==3">
                <div class="totalMoney">
                  <div class="label">应付金额：</div>
                  <div class="totalPrice">{{goodsCart.accountsPayable}} 元</div>
                </div>
              </div>
              <div v-if="type===3">
                <div class="totalMoney">
                  <div class="label">应退金额：</div>
                  <div class="totalPrice">{{goodsCart.refundAmount}} 元</div>
                </div>
              </div>
              <div v-if="type!==3">
                <div class="totalMoney">
                  <div class="label">实付金额：</div>
                  <div class="totalPrice">{{parseFloat(goodsCart.paymentAmount).toFixed(2)}} 元</div>
                </div>
              </div>
              <div v-if="type===3">
                <div class="totalMoney">
                  <div class="label">实退金额：</div>
                  <div class="totalPrice">{{parseFloat(goodsCart.realRefundAmount).toFixed(2)}} 元</div>
                </div>
              </div>

            </div>
          </el-col>
          <el-col :span="17">
            <div class="bottomBox">
              <span class="title">备注信息：</span>
              <span>{{goodsCart.remark}}</span>
            </div>
          </el-col>
        </el-row>
      </div>
    </el-dialog>

    <!-- 打印大票 -->
    <iframe style="display: none;" :srcdoc="tmpHtml" id="ifr"></iframe>
  </div>
</template>

<script>
  import { getLodop } from '/public/static/js/utils/LodopFuncs.js'
  export default {
    name: 'DialogPlayer',
    props: {
      /*checkSupplierIds: {
        type:Array,
        default:null
      },
      checkSupplierNames:{
        type:Array,
        default:null
      },*/
    },
    data () {
      return {
        message: '进货开单确认订单',
        supplierName: {
          default: '-'
        },
        supplier: {},
        goodsCart: {
          supplierId: 0,
          accountsPayable: 99.99,//应付金额
          paymentAmount: 99.99,//实付金额
          remark: '',
          details: []
        },
        supplierIds: [],
        checkSupplierIds: 0,
        sureDialogVisible: false,
        searchInput: '',
        SUPPLIER_STATUS: SUPPLIER_STATUS,
        currentRow: null,
        pageShow: true,
        //分页
        list: {
          type: Function,
          default: null
        },
        //显示的页码数，共50页，显示5页，其他用...表示
        itemCount: Number,
        page: 0,
        size: 10,
        totalSize: 0,
        pageTotal: 0,
        pages: [],
        input: null,
        //选中
        checkSuppliers: 0,
        strSuppliers: '',
        type: 1,//type=1商品管理，=2进货
        createTime:'',
        pickerOptions: {
          shortcuts: [{
            text: '今天',
            onClick (picker) {
              picker.$emit('pick', new Date())
            }
          }, {
            text: '昨天',
            onClick (picker) {
              const date = new Date()
              date.setTime(date.getTime() - 3600 * 1000 * 24)
              picker.$emit('pick', date)
            }
          }, {
            text: '一周前',
            onClick (picker) {
              const date = new Date()
              date.setTime(date.getTime() - 3600 * 1000 * 24 * 7)
              picker.$emit('pick', date)
            }
          }]
        },

        isPrint: false, //是否打印小票
        isPrintBig: false, //是否打印大票
        tmpHtml: '',

        order: {},
        tenantInfo: {},

        PRINT_MSG: PRINT_MSG,
      }
    },
    mounted () {
      //console.log('进来了：', this.message)
      this.tenantInfo = LocalStorage.get(TENANT_INFO);
    },
    methods: {
      printBigChange () {
        this.isPrint = false
      },
      printSmallChange () {
        this.isPrintBig = false
      },
      dialogClose () {
        this.$nextTick(() => {
          /*this.$refs.ossPlayer.pause()*/
        })
      },
      sureOrders (goodsCart, type) {
        if (type === 2) {
          this.type = 2
        }
        if (type === 1) {
          this.type = 1
        }
        if (type === 3) {
          this.type = 3
        }

        this.supplier = SessionStorage.get(WANGLAIMINGXI_SUPPLIER);

        this.supplierIds = goodsCart.supplierId
        this.checkSupplierIds = goodsCart.supplierId
        this.supplierNames = goodsCart.supplierName
        this.strSuppliers = goodsCart.supplierName.toString()
        //注意，必须先打开对话框才能获取对话框的ref
        this.sureDialogVisible = true
        this.goodsCart = goodsCart
        this.goodsCart.refundAmount = goodsCart.accountsPayable
        this.goodsCart.realRefundAmount = goodsCart.paymentAmount
        let now=new Date();
        this.createTime=this.$moment(now).format('yyyy-MM-DD HH:mm:ss')
        //console.log('传入的供应ids：', this.supplierIds, '传入的供应商名称：', this.strSuppliers)

        this.isPrint = false //是否打印小票，默认否
        this.isPrintBig = false //是否打印大票，默认否
      },

      reSearch (data) {
        //console.log('data: ', data)
        this.getSearch(1, data)
      },

      //1.搜索
      searchInputChange (searchInput) {
        //console.log('搜索内容: ', searchInput)
      },

      handleChange (value) {
        //console.log('改变的opts：', value[0])
        this.size = value[0]
        this.$emit('pageSizeChange', this.size)
      },
      inputValue () {
        let goBtn = document.getElementById('goBtn')
        //console.log('输入的内容：', this.input)
        this.getSearch(this.input)
      },

      //多选
      handleCurrentChange (val) {
        this.currentRow = val
        //console.log('当前选中：', this.currentRow)
      },
      timePickerChange(e){
        //console.log("日期时间选择：",e)
      },
      save () {
        this.goodsCart.supplierId = this.goodsCart.supplierId.toString()
        this.goodsCart.supplierName = this.goodsCart.supplierName.toString()
        this.goodsCart.createTime=this.createTime
        for (let i = 0; i < this.goodsCart.details.length; i++) {
          this.goodsCart.details[i].packageLevel = this.goodsCart.details[i].buyPackageLevel
        }
        if (this.type === 1 || this.type === 2) {
          Loadings.show()
          this.$axios.post(process.env.VUE_APP_SERVER + '/psi/buyorder/save', this.goodsCart)
            .then((response) => {
              Loadings.hide()
              if (response.status === 200) {
                if (response.data.code === 200) {
                  this.$message({ type: 'success', message: '进货开单成功！', duration: 2000, offset: 110 });
                  //跳转到客户列表页面
                  this.sureDialogVisible = false
                  SessionStorage.remove(STOCK_CART)
                  this.goodsCart = {}
                  this.$emit('saveFinish', true)

                  if (this.isPrint || this.isPrintBig) {
                    if (!(this.tenantInfo && this.tenantInfo.printEnable == 'Y')) {
                      this.$Swal.fire({
                        icon: 'error',
                        position: 'center',
                        title: PRINT_MSG,
                        confirmButtonText: `知道了`,
                        confirmButtonColor: '#e92322',
                      });

                      setTimeout(function () {
                        let sweet = document.getElementsByClassName('swal2-container')[0]
                        sweet.style.zIndex = '3000'
                      }, 100)
                    } else {
                      if (this.isPrint || this.isPrintBig) {
                        this.printOrder(response.data.data)
                      }
                    }
                  }
                } else {
                  this.$message({ type: 'warning', message: response.data.msg, duration: 2000, offset: 110 });
                }
              } else {
                this.$message({ type: 'warning', message: '网络异常', duration: 2000, offset: 110 });
              }

            })
        }
        if (this.type === 3) {
          Loadings.show()
          this.$axios.post(process.env.VUE_APP_SERVER + '/psi/buyreturnorder/save', this.goodsCart)
            .then((response) => {
              Loadings.hide()
              if (response.status === 200) {
                if (response.data.code === 200) {
                  this.$message({ type: 'success', message: '进货退货开单成功！', duration: 2000, offset: 110 });
                  //跳转到客户列表页面
                  this.sureDialogVisible = false
                  SessionStorage.remove(STOCK_RETURN_CART)
                  this.goodsCart = {}
                  this.$emit('saveFinish', true)
                } else {
                  this.$message({ type: 'warning', message: response.data.msg, duration: 2000, offset: 110 });
                }
              } else {
                this.$message({ type: 'warning', message: '网络异常', duration: 2000, offset: 110 });
              }

            })
        }
      },
      printOrder (orderId) {
        this.$axios.get(process.env.VUE_APP_SERVER + '/psi/buyorder/detail?buyOrderId=' + orderId)
          .then((response) => {
            Loadings.hide()
            let resp = response.data
            this.order.accountsPayable=resp.data.accountsPayable;
            this.order.paymentAmount=resp.data.paymentAmount;
            this.order.buyOrderNo=resp.data.buyOrderNo;
            this.order.createTime=resp.data.createTime;
            this.order.status=resp.data.status;
            this.orderDetail=resp.data.details;
            this.order.canAgain=resp.data.canAgain;
            for (let i = 0; i < this.orderDetail.length; i++) {
              this.orderDetail[i].uuid = Tool.get8UUID(8)
              this.orderDetail[i].buyPackageLevel = this.orderDetail[i].packageLevel
              this.orderDetail[i].totalPrice = this.orderDetail[i].goodsPrice * this.orderDetail[i].goodsNumber
              /*this.orderDetail[i].packageLevel = this.orderDetail[i].goodsPackageLevel*/
            }
            this.order.remark=resp.data.remark;

            this.tmpHtml = `<div style="text-align: center;">` +
                          `<span style="font-size: 18px;">${this.tenantInfo.tenantName ? this.tenantInfo.tenantName + '进货单' : ''}</span>` +
                          `<div style="width: 100%; display:flex; justify-content:space-between; margin-top:10px; margin-bottom:10px;">` +
                          `<div style= "text-align: left;">` +
                          `<div>供应商：${this.supplier.supplierName}</div>` +
                          `<div>联系人：${this.supplier.supplierPerson}</div>` +
                          `<div>电话：${this.supplier.supplierPhone}</div>` +
                          `</div>` +
                          `<div style="text-align: left;">` +
                          `<div>No：${this.order.buyOrderNo}</div>` +
                          `<div>日期：${this.order.createTime}</div>` +
                          `</div>` +
                          `</div>` +
                          `<table width="100%" cellspacing="0" border="1" style="margin: auto; text-align: center;">` +
                          `<tr>` +
                          `<td>产品名称</td>` +
                          `<td>包装规格</td>` +
                          `<td>数量</td>` +
                          `<td>销货单价</td>` +
                          `<td>销货金额</td>` +
                          `</tr>`;

            let sumTotalPrice = 0;
            for (let i = 0; i < this.orderDetail.length; i++) {
              let item = this.orderDetail[i];
              if (item.buyPackageLevel == 1) {
                item.unit = item.middleUnitName;
              } else if (item.buyPackageLevel == 2) {
                item.unit = item.bigUnitName;
              }

              this.tmpHtml += 
                          `<tr style="font-size: 12px;">` +
                          `<td>${item.goodsName}</td>` +
                          `<td>${item.packingSpecification}</td>` +
                          `<td>${item.goodsNumber + item.unit}</td>` +
                          `<td>${item.goodsPrice + '元/' + item.unit}</td>` +
                          `<td>￥${item.totalPrice}</td>` +
                          `</tr>`;

              sumTotalPrice += item.totalPrice;

              if (i == (this.orderDetail.length - 1)) {
                this.tmpHtml += 
                          `<tr style="font-size: 12px;">` +
                          `<td>合计：</td>` +
                          `<td></td>` +
                          `<td></td>` +
                          `<td></td>` +
                          `<td>￥${sumTotalPrice}</td>` +
                          `</tr>`;
              }
            }

            this.tmpHtml += `</table>` +
                          `<div style="width: 100%; display:flex; justify-content:space-between; margin-top:100px; margin-bottom:10px;">` +
                          `<div style= "text-align: left; width:200px;">` +
                          `<div>应收金额：￥${this.order.accountsPayable}</div>` +
                          `<div>制单人：${this.tenantInfo.userName}</div>` +
                          `</div>` +
                           `<div style= "text-align: left;">` +
                          `<div>实收金额：￥${this.order.paymentAmount}</div>` +
                          `<div>售货单位：${this.tenantInfo.tenantName}</div>` +
                          `</div>` +
                          `<div style="text-align: left;">` +
                          `<div>电话：${this.tenantInfo.account}</div>` +
                          `<div>备注：${this.order.remark}</div>` +
                          `</div>` +
                          `</div>` +
                          `<div>注意事项：所有农药产品，请按产品标签说明使用</div>`+
                          `</div>`;


            setTimeout(() => {
              //打印票据
              if (this.isPrint) {
                this.print()
              } else if (this.isPrintBig) {
                this.printBig();
              }
            }, 200);
          });
      },
      //打印小票
      print() {
        let LODOP = getLodop();

        if (LODOP === 'Error:DriveNotInstalled') {
          //console.log('打印插件未安装！');
          this.$message({ type: 'error', message: '打印插件未安装！请点击右下角悬浮图标，进行下载安装。', duration: 2000, offset: 110 });
          return;
        } else if (LODOP === 'Error:DriverNeedsToBeUpgraded') {
          //console.log('打印插件需升级！');
          this.$message({ type: 'error', message: '打印插件需升级！请点击右下角悬浮图标，进行下载安装。', duration: 2000, offset: 110 });
          return;
        }

        let topDistance = 5; //距离顶部距离
        LODOP.NEWPAGEA();
        LODOP.ADD_PRINT_TEXT(topDistance, 20, 580, 40, '*慧台账进销存*');
        LODOP.SET_PRINT_STYLEA(0,"FontSize",15);
        LODOP.SET_PRINT_STYLEA(0,"FontName","微软雅黑");

        topDistance += 50;
        LODOP.ADD_PRINT_TEXT(topDistance, 5, 580, 40, '开单时间：');
        LODOP.SET_PRINT_STYLEA(0,"FontSize",8);
        LODOP.SET_PRINT_STYLEA(0,"FontName","微软雅黑");

        topDistance += 1;
        LODOP.ADD_PRINT_TEXT(topDistance,55,130,40,this.order.createTime);

        topDistance += 20;
        LODOP.ADD_PRINT_TEXT(topDistance, 5, 580, 40, '订单编号：');
        LODOP.SET_PRINT_STYLEA(0,"FontSize",8);
        LODOP.SET_PRINT_STYLEA(0,"FontName","微软雅黑");

        topDistance += 1;
        LODOP.ADD_PRINT_TEXT(topDistance,55,130,60,this.order.buyOrderNo);

        topDistance += 40;
        LODOP.ADD_PRINT_TEXT(topDistance, 0, 360, 54, '---------------------------------------------');

        topDistance += 20;
        LODOP.ADD_PRINT_TEXT(topDistance, 42, 580, 40, '【供应商信息】');
        LODOP.SET_PRINT_STYLEA(0,"FontSize",12);
        LODOP.SET_PRINT_STYLEA(0,"FontName","微软雅黑");

        topDistance += 30;
        LODOP.ADD_PRINT_TEXT(topDistance,5,580,40,'店铺名称：');
        LODOP.SET_PRINT_STYLEA(0,"FontSize",10);
        LODOP.SET_PRINT_STYLEA(0,"FontName","微软雅黑");

        let height_supplierName = 0;
        if (this.supplier.supplierName.length > 9) {
          height_supplierName += (Math.ceil(this.supplier.supplierName.length / 9) - 1) * 17;
        }

        topDistance += 3;
        LODOP.ADD_PRINT_TEXT(topDistance,65,120,height_supplierName,this.supplier.supplierName);

        if (this.supplier.supplierName.length > 9) {
          topDistance += Math.ceil(this.supplier.supplierName.length / 9) * 17;
        } else {
          topDistance += 20;
        }

        LODOP.ADD_PRINT_TEXT(topDistance,5,580,40,'联系人：');
        LODOP.SET_PRINT_STYLEA(0,"FontSize",10);
        LODOP.SET_PRINT_STYLEA(0,"FontName","微软雅黑");

        let height_supplierPerson = 0;
        if (this.supplier.supplierPerson.length > 9) {
          height_supplierPerson += Math.ceil(this.supplier.supplierPerson.length / 9) * 17;
        }

        topDistance += 3;
        LODOP.ADD_PRINT_TEXT(topDistance,65,120,height_supplierPerson,this.supplier.supplierPerson);

        if (this.supplier.supplierPerson.length > 9) {
          topDistance += Math.ceil(clinet.supplierPerson.length / 9) * 17;
        } else {
          topDistance += 20;
        }

        LODOP.ADD_PRINT_TEXT(topDistance,5,580,40,'联系方式：');
        LODOP.SET_PRINT_STYLEA(0,"FontSize",10);
        LODOP.SET_PRINT_STYLEA(0,"FontName","微软雅黑");

        topDistance += 3;
        LODOP.ADD_PRINT_TEXT(topDistance,65,120,40,this.supplier.supplierPhone);

        topDistance += 30;

        LODOP.ADD_PRINT_TEXT(topDistance, 0, 360, 54, '---------------------------------------------');

        topDistance += 20;
        LODOP.ADD_PRINT_TEXT(topDistance, 42, 580, 40, '【订单详情】');
        LODOP.SET_PRINT_STYLEA(0,"FontSize",12);
        LODOP.SET_PRINT_STYLEA(0,"FontName","微软雅黑");

        topDistance += 30;

        this.orderDetail.map((item, index) => {
          LODOP.ADD_PRINT_TEXT(topDistance,5,580,40,'商品名称：');
          LODOP.SET_PRINT_STYLEA(0,"FontSize",10);
          LODOP.SET_PRINT_STYLEA(0,"FontName","微软雅黑");

          let height_goodsName = 0;
          if (item.goodsName.length > 9) {
            height_goodsName += Math.ceil(item.goodsName.length / 9) * 17;
          }

          topDistance += 3;
          LODOP.ADD_PRINT_TEXT(topDistance,65,120,height_goodsName,item.goodsName);

          if (item.goodsName.length > 9) {
            topDistance += Math.ceil(item.goodsName.length / 9) * 17;
          } else {
            topDistance += 20;
          }

          LODOP.ADD_PRINT_TEXT(topDistance,5,580,40,'包装规格：');
          LODOP.SET_PRINT_STYLEA(0,"FontSize",10);
          LODOP.SET_PRINT_STYLEA(0,"FontName","微软雅黑");

          let height_packingSpecification = 0;
          if (item.packingSpecification.length > 9) {
            height_packingSpecification += Math.ceil(item.packingSpecification.length / 9) * 17;
          }

          topDistance += 3;
          LODOP.ADD_PRINT_TEXT(topDistance,65,120,height_packingSpecification,item.packingSpecification);

          if (item.packingSpecification.length > 9) {
            topDistance += Math.ceil(item.packingSpecification.length / 9) * 17;
          } else {
            topDistance += 20;
          }

          LODOP.ADD_PRINT_TEXT(topDistance,5,580,40,'商品单价：');
          LODOP.SET_PRINT_STYLEA(0,"FontSize",10);
          LODOP.SET_PRINT_STYLEA(0,"FontName","微软雅黑");

          topDistance += 3;
          LODOP.ADD_PRINT_TEXT(topDistance,65,120,45,'￥' + item.goodsPrice);

          topDistance += 20;
          LODOP.ADD_PRINT_TEXT(topDistance,5,580,40,'商品数量：');
          LODOP.SET_PRINT_STYLEA(0,"FontSize",10);
          LODOP.SET_PRINT_STYLEA(0,"FontName","微软雅黑");

          let unit = '';
          if (item.buyPackageLevel == 1) {
            unit = item.middleUnitName;
          } else if (item.buyPackageLevel == 2) {
            unit = item.bigUnitName;
          }

          topDistance += 3;
          LODOP.ADD_PRINT_TEXT(topDistance,65,120,45,item.goodsNumber + unit);

          topDistance += 20;
          LODOP.ADD_PRINT_TEXT(topDistance, 5, 172, 40, '————————');

          topDistance += 20;
          LODOP.ADD_PRINT_TEXT(topDistance,5,580,40,'小计：');
          LODOP.SET_PRINT_STYLEA(0,"FontSize",10);
          LODOP.SET_PRINT_STYLEA(0,"FontName","微软雅黑");

          topDistance += 3;
          LODOP.ADD_PRINT_TEXT(topDistance,65,120,45,'￥' + item.totalPrice);

          if (index != this.orderDetail.length - 1) {
            topDistance += 20;
            LODOP.ADD_PRINT_TEXT(topDistance, 15, 172, 40, '*************************');
            topDistance += 20;
          }

        });

        topDistance += 25;
        LODOP.ADD_PRINT_TEXT(topDistance, 55, 172, 40, '=========================');

        topDistance += 20;
        LODOP.ADD_PRINT_TEXT(topDistance,85,580,40,'应付：');
        LODOP.SET_PRINT_STYLEA(0,"FontSize",10);
        LODOP.SET_PRINT_STYLEA(0,"FontName","微软雅黑");

        topDistance += 3;
        LODOP.ADD_PRINT_TEXT(topDistance,120,120,45,'￥' + this.order.accountsPayable);

        topDistance += 20;
        LODOP.ADD_PRINT_TEXT(topDistance,85,580,40,'实付：');
        LODOP.SET_PRINT_STYLEA(0,"FontSize",10);
        LODOP.SET_PRINT_STYLEA(0,"FontName","微软雅黑");

        topDistance += 3;
        LODOP.ADD_PRINT_TEXT(topDistance,120,120,45,'￥' + this.order.paymentAmount);

        topDistance += 20;
        LODOP.ADD_PRINT_TEXT(topDistance, 0, 360, 54, '---------------------------------------------');

        topDistance += 20;
        LODOP.ADD_PRINT_TEXT(topDistance, 42, 580, 40, '【店铺信息】');
        LODOP.SET_PRINT_STYLEA(0,"FontSize",12);
        LODOP.SET_PRINT_STYLEA(0,"FontName","微软雅黑");

        topDistance += 30;
        LODOP.ADD_PRINT_TEXT(topDistance,5,580,40,'店铺名称：');
        LODOP.SET_PRINT_STYLEA(0,"FontSize",10);
        LODOP.SET_PRINT_STYLEA(0,"FontName","微软雅黑");

        let tenantInfo = this.tenantInfo;

        let height_tenantName = 0;
        if (tenantInfo.tenantName.length > 9) {
          height_tenantName += Math.ceil(tenantInfo.tenantName.length / 9) * 17;
        }

        topDistance += 3;
        LODOP.ADD_PRINT_TEXT(topDistance,65,120,height_tenantName,tenantInfo.tenantName);

        if (tenantInfo.tenantName.length > 9) {
          topDistance += Math.ceil(tenantInfo.tenantName.length / 9) * 17;
        } else {
          topDistance += 20;
        }

        LODOP.ADD_PRINT_TEXT(topDistance,5,580,40,'联系方式：');
        LODOP.SET_PRINT_STYLEA(0,"FontSize",10);
        LODOP.SET_PRINT_STYLEA(0,"FontName","微软雅黑");

        topDistance += 3;
        LODOP.ADD_PRINT_TEXT(topDistance,65,120,40,tenantInfo.account);

        //设定打印页面大小
        LODOP.SET_PRINT_PAGESIZE(3,580,24,"客户往来明细单据打印");
        LODOP.PREVIEW();
      },
      //打印大票
      printBig() {
        //打印
        document.getElementById('ifr').contentWindow.print();
      },
      getAuthorityMenu(id){
        return Tool.authorityUserResources(id)
      },
    }
  }
</script>

<style scoped>
  /deep/ .el-dialog {
    border-radius: 10px;
  }

  /deep/ .el-button.is-round {
    padding: 8px;
  }

  /deep/ .el-dialog__header {
    padding: 0;
  }

  /deep/ .el-dialog--center .el-dialog__body {
    background: #d2d3d04d;
  }

  /deep/ .el-dialog__body {
    padding: 10px;
  }

  /deep/ .el-dialog__headerbtn .el-dialog__close {
    color: white;
    font-size: 24px;
  }

  .player-btn {
    font-size: 24px;
  }

  .header-title {
    background-color: #e52a33;
    color: white;
    font-size: 20px;
    display: inline-block;
    width: 100%;
    line-height: 60px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }

  .header-title .title {
    float: left;
    cursor: pointer;
    margin-left: 30px;
  }

  .verticalLine {
    position: absolute;
    top: 20px;
    float: left;
    left: 150px;
    width: 3px;
    height: 20px;
    border-radius: 40px;
    background-color: #ffffff;
  }

  .header-title .titleDesc {
    float: left;
    margin-left: 30px;
    font-size: 18px;
    line-height: 30px;
    cursor: pointer;
  }

  .header-title .supplierName {
    float: left;
    bottom: 5px;
    margin-left: 10px;
    color: #eeeeee;
    height: 40px;
    text-decoration: none;
    cursor: pointer;
  }

  .header-title .supplierName:hover, .header-title .supplierName:focus {
    color: #f6e483;
  }

  /deep/ .el-dialog__close:before {
    color: #ffffff;
  }

  /*搜索*/
  .showCheck {
    float: left;
    line-height: 40px;
    font-size: 16px;
    margin-right: 10px;
    height: 40px;
    max-width: 585px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .showCheck span {
    color: rgba(255, 102, 0, 0.9);
  }

  .navRight {
    float: right;
  }

  /*表格*/
  .orderNav {
    position: relative;
    width: 100%;
    margin-bottom: 50px;
  }

  .searchInput {
    width: 300px;
    float: left;
    margin-right: 10px;
  }

  .searchBtn {
    margin-right: 10px;
    float: left;
  }


  /*订单表格*/
  .orderTable {
    margin-bottom: 10px;
  }

  /deep/ .el-table .el-table__cell {
    padding: 6px 0;
  }

  .remark div {
    text-align: left;
  }

  .cartNum {
    color: #e52a33 !important;
  }

  /*小计*/
  .bottomMoney {
    margin-top: 20px;
    margin-right: 10px;
  }

  .bottomBox {
    margin-top: 25px;
    min-height: 70px;
  }

  .bottomBox .title {
    font-weight: 700;
    color: #000000;
  }

  .bottomBox span {
    float: left;
    margin-left: 10px;
    line-height: 30px;
  }

  .totalMoney {
    display: inline-block;
    width: 100%;
    margin-top: -5px;
  }

  .totalMoney .label {
    float: left;
    color: black;
    line-height: 40px;
    font-size: 15px;
  }

  /*.verticalRemarkLine{
    position: absolute;
    width: 2px;
    background: #dddddd;
    height: 100%;
    top: 0;
    left: 300px;
  }*/
  .bottomBox {
    border: 1px solid #dddddd;
    background: rgb(249, 249, 249);
    float: left;
    border-radius: 10px;
    width: 100%;
  }

  .bottomBox .remarkTitle {
    color: black;
    font-size: 15px;
    line-height: 14px;
    padding: 0 10px;
    margin-top: 15px;
    width: 100%;
  }

  .bottomBox .remarkDesc {
    float: left;
    color: black;
    font-size: 15px;
    line-height: 25px;
    text-align: left;
    padding: 10px 10px 0 10px;

  }

  .totalPrice {
    float: left;
    font-size: 18px;
    font-weight: 700;
    color: #e92322;
    line-height: 40px;
    position: relative;
  }

  .totalPrice:after {
    position: absolute;
    content: "";
    width: 100%;
    height: 2px;
    background: #e92322;
    left: 0;
    bottom: 5px;
  }
  .timeCheck {
    float: left;
  }

  .timePickers {
    width: 220px !important;
  }
</style>
