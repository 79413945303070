var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('el-dialog',{staticClass:"dialogDetail",attrs:{"width":"65%","visible":_vm.centerDialogVisible,"close-on-click-modal":false},on:{"close":_vm.dialogClose,"update:visible":function($event){_vm.centerDialogVisible=$event}}},[_c('div',{staticClass:"header-title",attrs:{"slot":"title"},slot:"title"},[_c('div',{staticClass:"title"},[_vm._v("用法用量")])]),_c('div',{staticClass:"orderNav"},[_c('el-form',{ref:"good",staticClass:"demo-good store-info-form",attrs:{"label-width":"120px"}},[_c('el-row',{staticClass:"rowForm",attrs:{"gutter":20}},[_c('el-col',{attrs:{"span":15}},[_c('el-form-item',{staticClass:"labelTitle",attrs:{"label":"商品名称","prop":"goodName"}},[_c('el-input',{staticClass:"inputLine",attrs:{"disabled":""},model:{value:(_vm.good.goodsName),callback:function ($$v) {_vm.$set(_vm.good, "goodsName", $$v)},expression:"good.goodsName"}})],1),_c('el-form-item',{staticClass:"labelTitle",attrs:{"label":"包装规格","prop":"packingSpecification"}},[_c('el-input',{staticClass:"inputLine",attrs:{"disabled":""},model:{value:(_vm.good.packageUnit),callback:function ($$v) {_vm.$set(_vm.good, "packageUnit", $$v)},expression:"good.packageUnit"}})],1),_c('el-form-item',{staticClass:"labelTitle",attrs:{"label":"生成厂家","prop":"packingSpecification"}},[_c('el-input',{staticClass:"inputLine",attrs:{"disabled":""},model:{value:(_vm.good.companyName),callback:function ($$v) {_vm.$set(_vm.good, "companyName", $$v)},expression:"good.companyName"}})],1),_c('el-form-item',{staticClass:"labelTitle",attrs:{"label":"用药时间","prop":"saleBigPrice"}},[_c('div',{staticClass:"yytime"},[_c('el-time-select',{staticClass:"mytimeclass",attrs:{"picker-options":{
                        start: '08:30',
                        step: '00:01',
                        end: '18:30'
                      }},model:{value:(_vm.yytime),callback:function ($$v) {_vm.yytime=$$v},expression:"yytime"}}),_c('span',{staticClass:"yytimejoin"},[_vm._v("至")]),_c('el-time-select',{staticClass:"mytimeclass",attrs:{"picker-options":{
                        start: '08:30',
                        step: '00:01',
                        end: '18:30'
                      }},model:{value:(_vm.yytimeend),callback:function ($$v) {_vm.yytimeend=$$v},expression:"yytimeend"}})],1)]),_c('el-form-item',{staticClass:"labelTitle",attrs:{"label":"每亩用量","prop":"packingSpecification"}},[_c('el-input',{staticClass:"inputLine",attrs:{"type":"number","maxlength":"50"},on:{"blur":function($event){return _vm.mmylblur($event)}},model:{value:(_vm.yl),callback:function ($$v) {_vm.yl=$$v},expression:"yl"}}),_c('span',{staticClass:"mmclass"},[_vm._v(_vm._s(_vm.good.smallUnitName)+"/亩")])],1),_c('el-form-item',{staticClass:"labelTitle",attrs:{"label":"用药方法","prop":"packingSpecification"}},[_c('el-select',{staticClass:"cftype",model:{value:(_vm.valueyyfa),callback:function ($$v) {_vm.valueyyfa=$$v},expression:"valueyyfa"}},_vm._l((_vm.yyfalist),function(item){return _c('el-option',{key:item.usageId,attrs:{"props":{value:'usageId',label:'usageName'},"label":item.usageName,"value":item.usageId}})}),1)],1)],1),_c('el-col',{attrs:{"span":9}},[_c('el-card',{staticClass:"imageBlock"},[(_vm.good.goodsImg)?_c('el-image',{staticClass:"uploadImage",attrs:{"fit":"scale-down","src":_vm.base_url+'/goods'+_vm.good.goodsImg}}):_vm._e(),_c('span',{staticClass:"imageDesc"},[_vm._v("商品图片")])],1)],1)],1),_c('div',{staticClass:"bottomBtn"},[_c('el-button',{staticClass:"storeInfoBtn",attrs:{"icon":"el-icon-s-goods","type":"danger"},on:{"click":_vm.save}},[_vm._v("保存 ")])],1)],1)],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }