<template>
  <div>
    <el-dialog width="65%" @close="dialogClose"
               :visible.sync="sureDialogVisible"
               class="dialogDetail"
               :close-on-click-modal="false">
      <div slot="title" class="header-title">
        <div class="title">{{type===3?'销货退货开单':'销货开单'}} - 确认订单</div>
      </div>
      <div class="orderNav">
        <div class="navLeft">
          <el-tag class="showCheck">当前客户
            <span v-if="checkClientIds.length>0">{{strClients}}</span></el-tag>
          <el-tag class="showCheck" type="info">购物车商品数量：
            <span class="cartNum">{{goodsCart.num}}</span></el-tag>
        </div>
        <div class="navRight">
          <el-checkbox v-if="type!==3 && getAuthorityMenu('300103')" v-model="isPrintBig" @change="printBigChange"
                       style="float: left; margin-right: 20px; margin-top: 10px;">打印大票
          </el-checkbox>
          <el-checkbox v-if="type!==3 && getAuthorityMenu('300103')" v-model="isPrint" @change="printSmallChange"
                       style="float: left; margin-right: 20px; margin-top: 10px;">打印小票
          </el-checkbox>
          <el-button v-if="type!==3 && getAuthorityMenu('300103')" class="searchBtn" type="danger" @click="save">确认开单
          </el-button>
          <el-button v-if="type===3 && getAuthorityMenu('300302')" class="searchBtn" type="danger" @click="save">
            确认退货开单
          </el-button>
          <!-- <el-button class="printOrder" type="warning" icon="el-icon-s-order" @click="printOrder()">打印单据</el-button> -->
        </div>

      </div>

      <div class="dialogBody">

        <div class="mainForm">
          <el-table
            ref="multipleTable"
            tooltip-effect="dark"
            :data="goodsCart.details"
            border
            height="400px"
            highlight-current-row
            class="previous-row"
            @current-change="handleCurrentChange"
            :header-cell-style="{backgroundImage:'linear-gradient(#f9f9f9,#f9f9f9)',color:'#909399',textAlign:'center'}"
          >
            <el-table-column type="index" label="序号" class="indexCenter" width="50"></el-table-column>
            <el-table-column property="goodsName" label="商品名称" width="160"></el-table-column>
            <el-table-column property="packageUnit" label="包装规格"></el-table-column>
            <el-table-column v-if="type!==3 && showRange" property="useRange" label="施用范围">
              <template slot-scope="scope">
                {{scope.row.useRange}}
              </template>
            </el-table-column>
            <el-table-column v-if="type!==3" property="packageLevel" label="选择包装" width="110" sortable>
              <template slot-scope="scope">
                <el-tag :type="scope.row.buyPackageLevel === 2 ? 'danger':'primary'" class="showCenter">
                  按{{scope.row.buyPackageLevel === 2?scope.row.bigUnitName:scope.row.middleUnitName}}{{(type===4 ||
                  type===5)?'销':'进'}}货
                </el-tag>
              </template>
            </el-table-column>
            <el-table-column v-if="type===3" property="packageLevel" label="退货包装" width="110" sortable>
              <template slot-scope="scope">
                <el-tag :type="scope.row.buyPackageLevel === 2 ? 'danger':'primary'" class="showCenter">
                  按{{scope.row.buyPackageLevel === 2?scope.row.bigUnitName:scope.row.middleUnitName}}退货
                </el-tag>
              </template>
            </el-table-column>

            <el-table-column v-if="type!==3" property="goodsPrice" label="商品单价" width="110" sortable>
              <template slot-scope="scope">
                <el-tag :type="scope.row.goodsPrice <= 0 ? 'warning':'danger'" class="showCenter">
                  ￥ {{scope.row.goodsPrice}}
                </el-tag>
              </template>
            </el-table-column>
            <el-table-column v-if="type===3" property="goodsPrice" label="退货单价" width="110" sortable>
              <template slot-scope="scope">
                <el-tag :type="scope.row.goodsPrice <= 0 ? 'warning':'danger'" class="showCenter">
                  ￥ {{scope.row.goodsPrice}}
                </el-tag>
              </template>
            </el-table-column>

            <el-table-column property="goodsNumber" label="商品数量" width="110" sortable>
              <template slot-scope="scope">
                <el-tag :type="scope.row.buyPackageLevel===1 ? 'primary':'danger'" class="showCenter"
                        id="goodsNumber">{{scope.row.goodsNumber}}&nbsp;{{scope.row.buyPackageLevel===1?scope.row.middleUnitName:scope.row.bigUnitName}}
                </el-tag>
              </template>
            </el-table-column>
            <el-table-column property="totalPrice" label="小计" width="120px">
              <template slot-scope="scope">
                <el-tag type="warning" class="showCenter">
                  {{scope.row.totalPrice}}&nbsp;元
                </el-tag>
              </template>
            </el-table-column>
          </el-table>
        </div>

        <el-row :gutter="10" class="rowForm">
          <el-col :span="type!==3?10:8">
            <div class="bottomMoney">
              <div class="timeCheckBox" >
                <div class="label">开单时间：</div>
                <div class="timeCheck">
                  <el-date-picker class="timePickers"
                                  v-model="createTime"
                                  type="datetime"
                                  placeholder="选择日期时间"
                                  align="right"
                                  :clearable="false"
                                  :editable="false"
                                  @change="timePickerChange"
                                  value-format="yyyy-MM-dd HH:mm:ss"
                                  :picker-options="pickerOptions">
                  </el-date-picker>
                </div>
              </div>

              <div class="totalMoneyParent" v-if="type!==3">
                <div class="totalMoney">
                  <div class="label">应付金额：</div>
                  <div class="totalPrice">{{parseFloat(goodsCart.accountsPayable).toFixed(2)}} 元</div>
                </div>
                <div class="totalMoney">
                  <div class="label">实付金额：</div>
                  <div class="totalPriceBlack">{{parseFloat(goodsCart.paymentAmount).toFixed(2)}} 元</div>
                </div>
              </div>

              <div class="totalMoneyParent" v-if="type===3">
                <div class="totalMoney">
                  <div class="label">应退金额：</div>
                  <div class="totalPrice">{{parseFloat(goodsCart.refundAmount).toFixed(2)}} 元</div>
                </div>
                <div class="totalMoney">
                  <div class="label">实退金额：</div>
                  <div class="totalPrice">{{parseFloat(goodsCart.realRefundAmount).toFixed(2)}} 元</div>
                </div>
              </div>

              <div class="totalMoneyParent" v-if="type!==3">
                <div class="totalMoney">
                  <div class="label">优惠金额：</div>
                  <div class="totalPriceBlack">{{parseFloat(goodsCart.discountMoney).toFixed(2)}} 元</div>
                </div>
                <div class="totalMoney">
                  <div class="label">赊账金额：</div>
                  <div class="totalPrice" :class="parseFloat(goodsCart.creditMoney)===0?'blackTheme':''">
                    {{parseFloat(goodsCart.creditMoney).toFixed(2)}} 元
                  </div>
                </div>
              </div>
              <div v-if="type!==3" class="moneyDesc">【注：赊账金额 = 应付金额 - 优惠金额 - 实付金额】</div>
            </div>

          </el-col>
          <el-col :span="type!==3?14:16">
            <div class="bottomBox">
              <span class="title">备注信息：</span>
              <span>{{goodsCart.remark}}</span>
            </div>
          </el-col>
        </el-row>

        <!-- 打印大票 -->
        <iframe style="display: none;" :srcdoc="tmpHtml" id="ifr"></iframe>

      </div>
    </el-dialog>
  </div>
</template>

<script>
  import { getLodop } from '/public/static/js/utils/LodopFuncs.js'

  export default {
    name: 'DialogPlayer',
    props: {
      /*checkClientIds: {
        type:Array,
        default:null
      },
      checkClientNames:{
        type:Array,
        default:null
      },*/
    },
    data () {
      return {
        message: '销货开单确认订单',
        clientName: {
          default: '-'
        },
        client: {},
        tenantInfo: {},
        goodsCart: {
          clientId: 0,
          accountsPayable: 99.99,//应付金额
          paymentAmount: 99.99,//实付金额
          remark: '',
          details: []
        },
        clientIds: [],
        checkClientIds: 0,
        sureDialogVisible: false,
        searchInput: '',
        SUPPLIER_STATUS: SUPPLIER_STATUS,
        currentRow: null,
        pageShow: true,
        //分页
        list: {
          type: Function,
          default: null
        },
        //显示的页码数，共50页，显示5页，其他用...表示
        itemCount: Number,
        page: 0,
        size: 10,
        totalSize: 0,
        pageTotal: 0,
        pages: [],
        input: null,
        //选中
        checkClients: 0,
        strClients: '',
        type: 1,//type=1商品管理，=2进货

        isPrint: false, //是否打印小票
        isPrintBig: false, //是否打印大票

        order: {},
        orderDetail: [],

        tmpHtml: '',

        PRINT_MSG: PRINT_MSG,

        recipe: {}, //处方数据
        createTime:'',
        pickerOptions: {
          shortcuts: [{
            text: '今天',
            onClick (picker) {
              picker.$emit('pick', new Date())
            }
          }, {
            text: '昨天',
            onClick (picker) {
              const date = new Date()
              date.setTime(date.getTime() - 3600 * 1000 * 24)
              picker.$emit('pick', date)
            }
          }, {
            text: '一周前',
            onClick (picker) {
              const date = new Date()
              date.setTime(date.getTime() - 3600 * 1000 * 24 * 7)
              picker.$emit('pick', date)
            }
          }]
        },
        isHLJ:false,
        showRange:false,
      }
    },
    mounted () {
      this.tenantInfo = LocalStorage.get(TENANT_INFO);
    },
    methods: {
      printBigChange () {
        this.isPrint = false
      },
      printSmallChange () {
        this.isPrintBig = false
      },
      dialogClose () {
        this.$nextTick(() => {
          /*this.$refs.ossPlayer.pause()*/
        })
      },
      sureOrders (goodsCart, type ,isNongYao) {
        this.client = SessionStorage.get(WANGLAIMINGXI_CLIENT);
        //2是原销货，3好像是退货，4为为销货，5为处方
        if (type) {
          this.type = type
        }
        this.clientIds = goodsCart.clientId
        this.checkClientIds = goodsCart.clientId
        this.clientNames = goodsCart.clientName
        this.strClients = goodsCart.clientName.toString()
        //注意，必须先打开对话框才能获取对话框的ref
        this.sureDialogVisible = true
        this.goodsCart = goodsCart
        // this.goodsCart.details.map(item=>{
        //   console.log("item----",item)
        //   if(item.categoryId.toString()[0]===2){
        //     this.showRange=true
        //   }
        // })
        if(this.goodsCart.details.some(item=>(item.categoryId.toString()[0]==2 || item.categoryId.toString()[0]==7))){
          this.showRange=true
        }else{
          this.showRange=false
        }

        this.goodsCart.isNongYao=isNongYao
        this.goodsCart.saleDetailList = this.goodsCart.details
        this.goodsCart.refundAmount = goodsCart.accountsPayable
        this.goodsCart.realRefundAmount = goodsCart.paymentAmount
        let now=new Date();
        this.createTime=this.$moment(now).format('yyyy-MM-DD HH:mm:ss')
        this.isPrint = false //是否打印小票，默认否
        this.isPrintBig = false //是否打印大票，默认否
        if(!Tool.isEmpty(SessionStorage.get(IS_HLJ))){
          this.isHLJ=SessionStorage.get(IS_HLJ)
        }

      },

      reSearch (data) {
        this.getSearch(1, data)
      },

      //1.搜索
      searchInputChange (searchInput) {
        //console.log('搜索内容: ', searchInput)
      },

      handleChange (value) {
        this.size = value[0]
        this.$emit('pageSizeChange', this.size)
      },
      inputValue () {
        let goBtn = document.getElementById('goBtn')
        this.getSearch(this.input)
      },

      //多选
      handleCurrentChange (val) {
        this.currentRow = val
      },
      timePickerChange(e){
        //console.log("日期时间选择：",e)
      },
      save () {
        let _this = this
        _this.goodsCart.clientId = _this.goodsCart.clientId.toString()
        _this.goodsCart.clientName = _this.goodsCart.clientName.toString()
        _this.goodsCart.createTime=_this.createTime
        let pesticideCodes=[]
        if(_this.type!==3){
          if(SessionStorage.get(HLJ_MODE) && SessionStorage.get(HLJ_MODE)===1){
            if(SessionStorage.get(CODE_URL_LIST)){
              let codes=SessionStorage.get(CODE_URL_LIST)
              if(codes.length>0){
                for(let i=0;i<codes.length;i++){
                  pesticideCodes.push(codes[i].codeUrl)
                }
              }
            }
            if(pesticideCodes.length>0){
              pesticideCodes=Array.from(new Set(pesticideCodes))
            }
          }


          for (let i = 0; i < _this.goodsCart.details.length; i++) {
            _this.goodsCart.details[i].packageLevel = _this.goodsCart.details[i].buyPackageLevel
            if(_this.goodsCart.details[i].codeUrl && (this.isHLJ && SessionStorage.get(HLJ_MODE)!==1)){
              pesticideCodes.push(_this.goodsCart.details[i].codeUrl)
            }
          }
        }else{
          if(SessionStorage.get(HLJ_RETURN_MODE) && SessionStorage.get(HLJ_RETURN_MODE)===1){
            if(SessionStorage.get(CODE_URL_RETURN_LIST)){
              let codes=SessionStorage.get(CODE_URL_RETURN_LIST)
              if(codes.length>0){
                for(let i=0;i<codes.length;i++){
                  pesticideCodes.push(codes[i].codeUrl)
                }
              }
            }
            if(pesticideCodes.length>0){
              pesticideCodes=Array.from(new Set(pesticideCodes))
            }
          }

          for (let i = 0; i < _this.goodsCart.details.length; i++) {
            _this.goodsCart.details[i].packageLevel = _this.goodsCart.details[i].buyPackageLevel
            if(_this.goodsCart.details[i].codeUrl && (this.isHLJ && SessionStorage.get(HLJ_RETURN_MODE)!==1)){
              pesticideCodes.push(_this.goodsCart.details[i].codeUrl)
            }
          }
        }


        if (_this.type === 1 || _this.type === 2 || _this.type === 4 || _this.type === 5) {
          let toastMsg = ''
          if (_this.type === 5) {
            toastMsg = '处方开单成功！'
            _this.goodsCart.orderType = 1
          } else if (_this.type === 4) {
            toastMsg = '销货开单成功！'
            _this.goodsCart.orderType = 0
          }
          _this.goodsCart.saleDetailList = _this.goodsCart.details
          _this.goodsCart.pesticideCodes = pesticideCodes
          //_this.goodsCart.details="";
          //console.log("保存的订单信息：",_this.goodsCart)
          Loadings.show()
          _this.$axios.post(process.env.VUE_APP_SERVER + '/psi/saleorder/save', _this.goodsCart)
            .then((response) => {
              Loadings.hide()
              if (response.status === 200) {
                if (response.data.code === 200) {
                  this.$message({
                    type: 'success',
                    message: '销货开单成功！',
                    duration: 2000,
                    offset: 110
                  })
                  //跳转到客户列表页面
                  _this.sureDialogVisible = false
                  SessionStorage.remove(SALE_CART)
                  SessionStorage.remove(SALE_RECIPE_DEAL_GOODS_DEL)
                  SessionStorage.remove(SALE_RECIPE_DEAL_GOODS)
                  SessionStorage.remove(SALE_RECIPE_DEAL_CART)
                  SessionStorage.remove(CODE_URL_LIST)
                  _this.$emit('saveFinish', true, _this.type)

                  if (this.isPrint || this.isPrintBig) {
                    if (!(this.tenantInfo && this.tenantInfo.printEnable == 'Y')) {
                      this.$Swal.fire({
                        icon: 'error',
                        position: 'center',
                        title: PRINT_MSG,
                        confirmButtonText: `知道了`,
                        confirmButtonColor: '#e92322',
                      });

                      setTimeout(function () {
                        let sweet = document.getElementsByClassName('swal2-container')[0]
                        sweet.style.zIndex = '3000'
                      }, 100);
                    } else {
                      if (this.isPrint) {
                        this.printOrder(response.data.data.saleOrderId)
                      } else if (this.isPrintBig) {
                        this.printBig(response.data.data.saleOrderId)
                      }
                    }
                  }
                } else {
                  this.$message({
                    type: 'warning',
                    message: response.data.msg,
                    duration: 2000,
                    offset: 110
                  })
                }
              } else {
                this.$message({
                  type: 'warning',
                  message: '网络异常',
                  duration: 2000,
                  offset: 110
                })
              }
            })
        }
        if (_this.type === 3) {
          _this.goodsCart.saleReturnDetailList = _this.goodsCart.details
          _this.goodsCart.refundAmount = _this.goodsCart.accountsPayable
          _this.goodsCart.realRefundAmount = _this.goodsCart.paymentAmount
          _this.goodsCart.pesticideCodes = pesticideCodes
          //_this.goodsCart.details="";
          Loadings.show()
          _this.$axios.post(process.env.VUE_APP_SERVER + '/psi/salereturnorder/save', _this.goodsCart)
            .then((response) => {
              Loadings.hide()
              if (response.status === 200) {
                if (response.data.code === 200) {
                  this.$message({
                    type: 'success',
                    message: '销货退货开单成功！',
                    duration: 2000,
                    offset: 110
                  })
                  //跳转到客户列表页面
                  _this.sureDialogVisible = false
                  SessionStorage.remove(SALE_RETURN_CART)
                  _this.$emit('saveFinish', true)
                } else {
                  this.$message({
                    type: 'warning',
                    message: response.data.msg,
                    duration: 2000,
                    offset: 110
                  })
                }
              } else {
                this.$message({
                  type: 'warning',
                  message: '网络异常',
                  duration: 2000,
                  offset: 110
                })
              }

            })
        }
      },
      printBig (saleOrderId) {
        this.$axios.get(process.env.VUE_APP_SERVER + '/psi/saleorder/detail?saleOrderId=' + saleOrderId)
          .then((response) => {
            Loadings.hide()
            let resp = response.data
            this.order.accountsPayable = resp.data.accountsPayable
            this.order.paymentAmount = resp.data.paymentAmount
            this.order.buyOrderNo = resp.data.saleOrderNo
            this.order.createTime = resp.data.createTime
            this.order.status = resp.data.status
            this.order.show = resp.data.show
            this.orderDetail = resp.data.saleDetailList
            for (let i = 0; i < this.orderDetail.length; i++) {
              this.orderDetail[i].uuid = Tool.get8UUID(8)
              this.orderDetail[i].buyPackageLevel = this.orderDetail[i].packageLevel
              if (this.orderDetail[i].goodsPackageLevel === 2) {

                // this.orderDetail[i].packingSpecification =
                //   parseFloat(this.orderDetail[i].smallCount).toFixed(2) + '/'
                //   + this.orderDetail[i].smallUnitName + ' x '
                //   + this.orderDetail[i].middleCount
                //   + this.orderDetail[i].middleUnitName
                //   + this.orderDetail[i].bigUnitName;

                this.orderDetail[i].packingSpecification =
                  parseFloat(this.orderDetail[i].smallCount).toFixed(2) + this.orderDetail[i].smallUnitName + '/'
                  + this.orderDetail[i].middleUnitName + ' x '
                  + this.orderDetail[i].middleCount
                  + this.orderDetail[i].middleUnitName + '/'
                  + this.orderDetail[i].bigUnitName
              }
              if (this.orderDetail[i].goodsPackageLevel === 1) {
                // this.orderDetail[i].packingSpecification =
                //   parseFloat(this.orderDetail[i].smallCount).toFixed(2) + '/'
                //   + this.orderDetail[i].smallUnitName

                this.orderDetail[i].packingSpecification =
                  parseFloat(this.orderDetail[i].smallCount).toFixed(2) + this.orderDetail[i].smallUnitName + '/' + this.orderDetail[i].middleUnitName
              }
              this.orderDetail[i].totalPrice = this.orderDetail[i].goodsPrice * this.orderDetail[i].goodsNumber
              /*this.orderDetail[i].packageLevel = this.orderDetail[i].goodsPackageLevel*/
            }
            this.order.remark = resp.data.remark

            //打印票据
            //构建大票内容
            //this.tmpHtml = `<p>${this.order.buyOrderNo}</p>`;
            //let div = document.getElementById('ifr').nextSibling;
            //this.tmpHtml = div.innerHTML;
            let isRange=false
            for (let i = 0; i < this.orderDetail.length; i++) {
              let item = this.orderDetail[i]
              if(item.categoryId.toString()[0]==='2' || item.categoryId.toString()[0]==='7'){
                isRange=true
              }
            }
            //console.log("isRange: ",isRange)
            this.tmpHtml = `<div style="text-align: center;">` +
                          `<span style="font-size: 18px;">${this.tenantInfo.tenantName ? this.tenantInfo.tenantName + '销货单' : ''}</span>` +
                          `<div style="width: 100%; display:flex; justify-content:space-between; margin-top:10px; margin-bottom:10px;">` +
                          `<div style= "text-align: left;">` +
                          `<div>购货单位（人）：${this.client.clientName ? this.client.clientName : this.client.clientPerson}</div>` +
                          `<div>电话：${this.client.clientPhone}</div>` +
                          //`<div>地址：河北省衡水市桃城区中华街道丽景社区155号</div>` +
                          `</div>` +
                          `<div style="text-align: left;">` +
                          `<div>No：${this.order.buyOrderNo}</div>` +
                          `<div>日期：${this.order.createTime}</div>` +
                          `</div>` +
                          `</div>` +
                          `<table width="100%" cellspacing="0" border="1" style="margin: auto; text-align: center;">` +
                          `<tr>` +
                          `<td>产品名称</td>` +
                          `<td>包装规格</td>` +
                          `<td style="display: ${isRange?'':'none'}">施用范围</td>`+
                          `<td>数量</td>` +
                          `<td>销货单价</td>` +
                          `<td>销货金额</td>` +
                          `</tr>`;

            let sumTotalPrice = 0;
            for (let i = 0; i < this.orderDetail.length; i++) {
              let item = this.orderDetail[i]

              if (item.buyPackageLevel == 1) {
                item.unit = item.middleUnitName
              } else if (item.buyPackageLevel == 2) {
                item.unit = item.bigUnitName
              }

              this.tmpHtml +=
                          `<tr style="font-size: 12px;">` +
                          `<td>${item.goodsName}</td>` +
                          `<td>${item.packingSpecification}</td>` +
                          `<td style="display: ${isRange?'':'none'}">${item.useRange}</td>` +
                          `<td>${item.goodsNumber + item.unit}</td>` +
                          `<td>${item.goodsPrice + '元/' + item.unit}</td>` +
                          `<td>￥${item.totalPrice}</td>` +
                          `</tr>`;

              sumTotalPrice += item.totalPrice;

              if (i == (this.orderDetail.length - 1)) {
                this.tmpHtml +=
                          `<tr style="font-size: 12px;">` +
                          `<td>合计：</td>` +
                          `<td></td>` +
                          `<td></td>` +
                          `<td></td>` +
                          `<td>￥${sumTotalPrice}</td>` +
                          `</tr>`;
              }
            }

            this.tmpHtml += `</table>` +
                          `<div style="width: 100%; display:flex; justify-content:space-between; margin-top:100px; margin-bottom:10px;">` +
                          `<div style= "text-align: left; width:200px;">` +
                          `<div>应收金额：￥${this.order.accountsPayable}</div>` +
                          `<div>制单人：${this.tenantInfo.userName}</div>` +
                          `</div>` +
                           `<div style= "text-align: left;">` +
                          `<div>实收金额：￥${this.order.paymentAmount}</div>` +
                          `<div>售货单位：${this.tenantInfo.tenantName}</div>` +
                          `</div>` +
                          `<div style="text-align: left;">` +
                          `<div>电话：${this.tenantInfo.account}</div>` +
                          `<div>备注：${this.order.remark}</div>` +
                          `</div>` +
                          `</div>` +
                          `<div>注意事项：所有农药产品，请按产品标签说明使用</div>`+
                          `</div>`;

            setTimeout(() => {
              document.getElementById('ifr').contentWindow.print()
            }, 1000)
          })
      },
      printOrder (saleOrderId) {
        this.$axios.get(process.env.VUE_APP_SERVER + '/psi/saleorder/detail?saleOrderId=' + saleOrderId)
          .then((response) => {
            Loadings.hide()
            let resp = response.data
            this.order.accountsPayable = resp.data.accountsPayable
            this.order.paymentAmount = resp.data.paymentAmount
            this.order.buyOrderNo = resp.data.saleOrderNo
            this.order.createTime = resp.data.createTime
            this.order.status = resp.data.status
            this.order.show = resp.data.show

            //订单类型
            this.order.orderType = resp.data.orderType
            //如果订单类型为农药处方开单，则查询处方详情
            if (this.order.orderType == 1) {
              this.recipe.recipeType = resp.data.recipeType
              this.recipe.recipeId = resp.data.recipeId
              this.$axios.get(process.env.VUE_APP_SERVER + '/psi/recipe/detail?recipeId=' + this.recipe.recipeId + '&recipeType=' + this.recipe.recipeType)
                .then((res) => {
                  //处方名称
                  this.recipe.recipeName = res.data.data.name
                  //防治对象名称
                  this.recipe.preventionName = res.data.data.preventionName
                  //作物
                  this.recipe.cropCategoryName = res.data.data.cropCategoryName
                  //注意事项
                  this.recipe.matters = res.data.data.matters
                })
            }

            this.orderDetail = resp.data.saleDetailList
            for (let i = 0; i < this.orderDetail.length; i++) {
              this.orderDetail[i].uuid = Tool.get8UUID(8)
              this.orderDetail[i].buyPackageLevel = this.orderDetail[i].packageLevel
              if (this.orderDetail[i].goodsPackageLevel === 2) {

                // this.orderDetail[i].packingSpecification =
                //   parseFloat(this.orderDetail[i].smallCount).toFixed(2) + '/'
                //   + this.orderDetail[i].smallUnitName + ' x '
                //   + this.orderDetail[i].middleCount
                //   + this.orderDetail[i].middleUnitName
                //   + this.orderDetail[i].bigUnitName;

                this.orderDetail[i].packingSpecification =
                  parseFloat(this.orderDetail[i].smallCount).toFixed(2) + this.orderDetail[i].smallUnitName + '/'
                  + this.orderDetail[i].middleUnitName + ' x '
                  + this.orderDetail[i].middleCount
                  + this.orderDetail[i].middleUnitName + '/'
                  + this.orderDetail[i].bigUnitName
              }
              if (this.orderDetail[i].goodsPackageLevel === 1) {
                // this.orderDetail[i].packingSpecification =
                //   parseFloat(this.orderDetail[i].smallCount).toFixed(2) + '/'
                //   + this.orderDetail[i].smallUnitName

                this.orderDetail[i].packingSpecification =
                  parseFloat(this.orderDetail[i].smallCount).toFixed(2) + this.orderDetail[i].smallUnitName + '/' + this.orderDetail[i].middleUnitName
              }
              this.orderDetail[i].totalPrice = this.orderDetail[i].goodsPrice * this.orderDetail[i].goodsNumber
              /*this.orderDetail[i].packageLevel = this.orderDetail[i].goodsPackageLevel*/
              //用法用量
              this.orderDetail[i].usageAndDosage = this.orderDetail[i].usageName + '，' + this.orderDetail[i].usageAmount + this.orderDetail[i].smallUnitName + '/亩'
              //console.log('++++++++++++++++++++++++++')
              //console.log(this.orderDetail[i].usageAndDosage)
            }
            this.order.remark = resp.data.remark

            setTimeout(() => {
              //打印票据
              this.print()
            }, 200)
          })
      },
      print () {
        let client = SessionStorage.get(WANGLAIMINGXI_CLIENT)

        let LODOP = getLodop()

        if (LODOP === 'Error:DriveNotInstalled') {
          //console.log('打印插件未安装！');
          this.$message({
            type: 'error',
            message: '打印插件未安装！请点击右下角悬浮图标，进行下载安装。',
            duration: 2000,
            offset: 110
          })
          return
        } else if (LODOP === 'Error:DriverNeedsToBeUpgraded') {
          //console.log('打印插件需升级！');
          this.$message({
            type: 'error',
            message: '打印插件需升级！请点击右下角悬浮图标，进行下载安装。',
            duration: 2000,
            offset: 110
          })
          return
        }

        if (this.order.orderType == 1) {
          let topDistance = 5 //距离顶部距离
          LODOP.NEWPAGEA()
          LODOP.ADD_PRINT_TEXT(topDistance, 20, 580, 40, '*作物用药处方*')
          LODOP.SET_PRINT_STYLEA(0, 'FontSize', 15)
          LODOP.SET_PRINT_STYLEA(0, 'FontName', '微软雅黑')

          topDistance += 50
          LODOP.ADD_PRINT_TEXT(topDistance, 5, 580, 40, '开单时间：')
          LODOP.SET_PRINT_STYLEA(0, 'FontSize', 8)
          LODOP.SET_PRINT_STYLEA(0, 'FontName', '微软雅黑')

          topDistance += 1
          LODOP.ADD_PRINT_TEXT(topDistance, 55, 130, 40, this.order.createTime)

          topDistance += 20
          LODOP.ADD_PRINT_TEXT(topDistance, 5, 580, 40, '订单编号：')
          LODOP.SET_PRINT_STYLEA(0, 'FontSize', 8)
          LODOP.SET_PRINT_STYLEA(0, 'FontName', '微软雅黑')

          topDistance += 1
          LODOP.ADD_PRINT_TEXT(topDistance, 55, 130, 60, this.order.buyOrderNo)

          topDistance += 40
          LODOP.ADD_PRINT_TEXT(topDistance, 0, 360, 54, '---------------------------------------------')

          topDistance += 20
          LODOP.ADD_PRINT_TEXT(topDistance, 42, 580, 40, '【客户信息】')
          LODOP.SET_PRINT_STYLEA(0, 'FontSize', 12)
          LODOP.SET_PRINT_STYLEA(0, 'FontName', '微软雅黑')
          topDistance += 30

          // LODOP.ADD_PRINT_TEXT(topDistance,5,580,40,'店铺名称：');
          // LODOP.SET_PRINT_STYLEA(0,"FontSize",10);
          // LODOP.SET_PRINT_STYLEA(0,"FontName","微软雅黑");

          // let height_clientName = 0;
          // if (client.clientName.length > 9) {
          //   height_clientName += (Math.ceil(client.clientName.length / 9) - 1) * 17;
          // }

          // topDistance += 3;
          // LODOP.ADD_PRINT_TEXT(topDistance,65,120,height_clientName,client.clientName);

          // if (client.clientName.length > 9) {
          //   topDistance += Math.ceil(client.clientName.length / 9) * 17;
          // } else {
          //   topDistance += 20;
          // }

          LODOP.ADD_PRINT_TEXT(topDistance, 5, 580, 40, '客户名称：')
          LODOP.SET_PRINT_STYLEA(0, 'FontSize', 10)
          LODOP.SET_PRINT_STYLEA(0, 'FontName', '微软雅黑')

          let height_clientPerson = 0
          if (client.clientPerson.length > 9) {
            height_clientPerson += Math.ceil(client.clientPerson.length / 9) * 17
          }

          topDistance += 3
          LODOP.ADD_PRINT_TEXT(topDistance, 65, 120, height_clientPerson, client.clientPerson)

          if (client.clientPerson.length > 9) {
            topDistance += Math.ceil(clinet.clientPerson.length / 9) * 17
          } else {
            topDistance += 20
          }

          LODOP.ADD_PRINT_TEXT(topDistance, 5, 580, 40, '联系方式：')
          LODOP.SET_PRINT_STYLEA(0, 'FontSize', 10)
          LODOP.SET_PRINT_STYLEA(0, 'FontName', '微软雅黑')

          topDistance += 3
          LODOP.ADD_PRINT_TEXT(topDistance, 65, 120, 40, client.clientPhone)

          topDistance += 30

          LODOP.ADD_PRINT_TEXT(topDistance, 0, 360, 54, '---------------------------------------------')

          topDistance += 20
          LODOP.ADD_PRINT_TEXT(topDistance, 42, 580, 40, '【处方信息】')
          LODOP.SET_PRINT_STYLEA(0, 'FontSize', 12)
          LODOP.SET_PRINT_STYLEA(0, 'FontName', '微软雅黑')
          topDistance += 30

          LODOP.ADD_PRINT_TEXT(topDistance, 5, 580, 40, '处方名称：')
          LODOP.SET_PRINT_STYLEA(0, 'FontSize', 10)
          LODOP.SET_PRINT_STYLEA(0, 'FontName', '微软雅黑')

          let recipeName = '[' + (this.recipe.recipeType == 0 ? '常规处方' : this.recipe.recipeType == 1 ? '专家处方' : this.recipe.recipeType == 2 ? '门店处方' : '无') + '] ' + this.recipe.recipeName

          let height_recipeName = 0
          if (recipeName.length > 9) {
            height_recipeName += Math.ceil(recipeName.length / 9) * 17
          }

          topDistance += 3

          LODOP.ADD_PRINT_TEXT(topDistance, 65, 120, height_recipeName, recipeName)

          if (recipeName.length > 9) {
            topDistance += Math.ceil(recipeName.length / 9) * 17
          } else {
            topDistance += 20
          }

          LODOP.ADD_PRINT_TEXT(topDistance, 5, 580, 40, '作物：')
          LODOP.SET_PRINT_STYLEA(0, 'FontSize', 10)
          LODOP.SET_PRINT_STYLEA(0, 'FontName', '微软雅黑')

          let height_cropCategoryName = 0
          if (this.recipe.cropCategoryName.length > 9) {
            height_cropCategoryName += Math.ceil(this.recipe.cropCategoryName.length / 9) * 17
          }

          topDistance += 3
          LODOP.ADD_PRINT_TEXT(topDistance, 65, 120, 40, this.recipe.cropCategoryName)

          if (this.recipe.cropCategoryName.length > 9) {
            topDistance += Math.ceil(this.recipe.cropCategoryName.length / 9) * 17
          } else {
            topDistance += 20
          }

          LODOP.ADD_PRINT_TEXT(topDistance, 5, 580, 40, '防治对象：')
          LODOP.SET_PRINT_STYLEA(0, 'FontSize', 10)
          LODOP.SET_PRINT_STYLEA(0, 'FontName', '微软雅黑')

          let height_preventionName = 0
          if (this.recipe.preventionName.length > 9) {
            height_preventionName += Math.ceil(this.recipe.preventionName.length / 9) * 17
          }

          topDistance += 3
          LODOP.ADD_PRINT_TEXT(topDistance, 65, 120, 40, this.recipe.preventionName)

          if (this.recipe.preventionName.length > 9) {
            topDistance += Math.ceil(this.recipe.preventionName.length / 9) * 17
          } else {
            topDistance += 20
          }

          LODOP.ADD_PRINT_TEXT(topDistance, 0, 360, 54, '---------------------------------------------')

          topDistance += 20
          LODOP.ADD_PRINT_TEXT(topDistance, 42, 580, 40, '【处方详情】')
          LODOP.SET_PRINT_STYLEA(0, 'FontSize', 12)
          LODOP.SET_PRINT_STYLEA(0, 'FontName', '微软雅黑')

          topDistance += 30

          this.orderDetail.map((item, index) => {
            LODOP.ADD_PRINT_TEXT(topDistance, 5, 580, 40, '商品名称：')
            LODOP.SET_PRINT_STYLEA(0, 'FontSize', 10)
            LODOP.SET_PRINT_STYLEA(0, 'FontName', '微软雅黑')

            let height_goodsName = 0
            if (item.goodsName.length > 9) {
              height_goodsName += Math.ceil(item.goodsName.length / 9) * 17
            }

            topDistance += 3
            LODOP.ADD_PRINT_TEXT(topDistance, 65, 120, height_goodsName, item.goodsName)

            if (item.goodsName.length > 9) {
              topDistance += Math.ceil(item.goodsName.length / 9) * 17
            } else {
              topDistance += 20
            }

            LODOP.ADD_PRINT_TEXT(topDistance, 5, 580, 40, '包装规格：')
            LODOP.SET_PRINT_STYLEA(0, 'FontSize', 10)
            LODOP.SET_PRINT_STYLEA(0, 'FontName', '微软雅黑')

            let height_packingSpecification = 0
            if (item.packingSpecification.length > 9) {
              height_packingSpecification += Math.ceil(item.packingSpecification.length / 9) * 17
            }

            topDistance += 3
            LODOP.ADD_PRINT_TEXT(topDistance, 65, 120, height_packingSpecification, item.packingSpecification)

            if (item.packingSpecification.length > 9) {
              topDistance += Math.ceil(item.packingSpecification.length / 9) * 17
            } else {
              topDistance += 20
            }

            LODOP.ADD_PRINT_TEXT(topDistance, 5, 580, 40, '施用范围：')
            LODOP.SET_PRINT_STYLEA(0, 'FontSize', 10)
            LODOP.SET_PRINT_STYLEA(0, 'FontName', '微软雅黑')

            let height_rangeSpecification = 0
            if (item.useRange.length > 9) {
              height_rangeSpecification += Math.ceil(item.useRange.length / 9) * 17
            }

            topDistance += 3
            LODOP.ADD_PRINT_TEXT(topDistance, 65, 120,height_rangeSpecification, item.useRange)

            if (item.useRange.length > 9) {
              topDistance += Math.ceil(item.useRange.length / 9) * 17
            } else {
              topDistance += 20
            }

            LODOP.ADD_PRINT_TEXT(topDistance, 5, 580, 40, '商品单价：')
            LODOP.SET_PRINT_STYLEA(0, 'FontSize', 10)
            LODOP.SET_PRINT_STYLEA(0, 'FontName', '微软雅黑')

            topDistance += 3
            LODOP.ADD_PRINT_TEXT(topDistance, 65, 120, 45, '￥' + item.goodsPrice)

            topDistance += 20
            LODOP.ADD_PRINT_TEXT(topDistance, 5, 580, 40, '商品数量：')
            LODOP.SET_PRINT_STYLEA(0, 'FontSize', 10)
            LODOP.SET_PRINT_STYLEA(0, 'FontName', '微软雅黑')

            let unit = ''
            if (item.buyPackageLevel == 1) {
              unit = item.middleUnitName
            } else if (item.buyPackageLevel == 2) {
              unit = item.bigUnitName
            }

            topDistance += 3
            LODOP.ADD_PRINT_TEXT(topDistance, 65, 120, 45, item.goodsNumber + unit)

            topDistance += 20
            LODOP.ADD_PRINT_TEXT(topDistance, 5, 580, 40, '用法用量：')
            LODOP.SET_PRINT_STYLEA(0, 'FontSize', 10)
            LODOP.SET_PRINT_STYLEA(0, 'FontName', '微软雅黑')

            topDistance += 3
            LODOP.ADD_PRINT_TEXT(topDistance, 65, 120, 45, item.usageAndDosage)

            topDistance += 20
            LODOP.ADD_PRINT_TEXT(topDistance, 5, 172, 40, '————————')

            topDistance += 20
            LODOP.ADD_PRINT_TEXT(topDistance, 5, 580, 40, '小计：')
            LODOP.SET_PRINT_STYLEA(0, 'FontSize', 10)
            LODOP.SET_PRINT_STYLEA(0, 'FontName', '微软雅黑')

            //topDistance += 3;
            LODOP.ADD_PRINT_TEXT(topDistance, 40, 120, 45, '￥' + item.totalPrice)

            if (index != this.orderDetail.length - 1) {
              topDistance += 20
              LODOP.ADD_PRINT_TEXT(topDistance, 15, 172, 40, '*************************')
              topDistance += 20
            }

          })

          topDistance += 25
          LODOP.ADD_PRINT_TEXT(topDistance, 55, 172, 40, '=========================')

          topDistance += 20
          LODOP.ADD_PRINT_TEXT(topDistance, 85, 580, 40, '应付：')
          LODOP.SET_PRINT_STYLEA(0, 'FontSize', 10)
          LODOP.SET_PRINT_STYLEA(0, 'FontName', '微软雅黑')

          topDistance += 3
          LODOP.ADD_PRINT_TEXT(topDistance, 120, 120, 45, '￥' + this.order.accountsPayable)

          topDistance += 20
          LODOP.ADD_PRINT_TEXT(topDistance, 85, 580, 40, '实付：')
          LODOP.SET_PRINT_STYLEA(0, 'FontSize', 10)
          LODOP.SET_PRINT_STYLEA(0, 'FontName', '微软雅黑')

          topDistance += 3
          LODOP.ADD_PRINT_TEXT(topDistance, 120, 120, 45, '￥' + this.order.paymentAmount)

          topDistance += 20

          LODOP.ADD_PRINT_TEXT(topDistance, 0, 360, 54, '---------------------------------------------')

          topDistance += 20
          LODOP.ADD_PRINT_TEXT(topDistance, 42, 580, 40, '【注意事项】')
          LODOP.SET_PRINT_STYLEA(0, 'FontSize', 12)
          LODOP.SET_PRINT_STYLEA(0, 'FontName', '微软雅黑')

          // let height_tenantName = 0;
          // if (tenantInfo.tenantName.length > 9) {
          //   height_tenantName += Math.ceil(tenantInfo.tenantName.length / 9) * 17;
          // }

          //this.recipe.matters = '1. 使用本品应采取相应的安全防护措施，穿戴防护服和防护手套、口罩等，避免皮肤接触和口鼻吸入。使用中不可吸烟、饮水及吃东西，使用后及时清洗手、脸等暴露部位皮肤并更换衣物，2. 严格按照推荐剂量量取药剂，不得任意增减用量，严禁用手搅拌药液。3. 避免孕妇及哺乳期妇女接触。';

          topDistance += 30
          LODOP.ADD_PRINT_TEXT(topDistance, 5, 180, 100, this.recipe.matters)

          if (this.recipe.matters.length > 9) {
            topDistance += Math.ceil(this.recipe.matters.length / 9) * 7
          } else {
            topDistance += 20
          }

          LODOP.ADD_PRINT_TEXT(topDistance, 0, 360, 54, '---------------------------------------------')

          topDistance += 20
          LODOP.ADD_PRINT_TEXT(topDistance, 42, 580, 40, '【店铺信息】')
          LODOP.SET_PRINT_STYLEA(0, 'FontSize', 12)
          LODOP.SET_PRINT_STYLEA(0, 'FontName', '微软雅黑')

          topDistance += 30
          LODOP.ADD_PRINT_TEXT(topDistance, 5, 580, 40, '店铺名称：')
          LODOP.SET_PRINT_STYLEA(0, 'FontSize', 10)
          LODOP.SET_PRINT_STYLEA(0, 'FontName', '微软雅黑')

          let tenantInfo = LocalStorage.get(TENANT_INFO)

          let height_tenantName = 0
          if (tenantInfo.tenantName.length > 9) {
            height_tenantName += Math.ceil(tenantInfo.tenantName.length / 9) * 17
          }

          topDistance += 3
          LODOP.ADD_PRINT_TEXT(topDistance, 65, 120, height_tenantName, tenantInfo.tenantName)

          if (tenantInfo.tenantName.length > 9) {
            topDistance += Math.ceil(tenantInfo.tenantName.length / 9) * 17
          } else {
            topDistance += 20
          }

          LODOP.ADD_PRINT_TEXT(topDistance, 5, 580, 40, '联系方式：')
          LODOP.SET_PRINT_STYLEA(0, 'FontSize', 10)
          LODOP.SET_PRINT_STYLEA(0, 'FontName', '微软雅黑')

          topDistance += 3
          LODOP.ADD_PRINT_TEXT(topDistance, 65, 120, 40, tenantInfo.account)

          topDistance += 20
          LODOP.ADD_PRINT_TEXT(topDistance, 5, 580, 40, '开方人签字：')
          LODOP.SET_PRINT_STYLEA(0, 'FontSize', 10)
          LODOP.SET_PRINT_STYLEA(0, 'FontName', '微软雅黑')

          LODOP.ADD_PRINT_TEXT(topDistance, 80, 120, 40, tenantInfo.userName)

          //设定打印页面大小
          LODOP.SET_PRINT_PAGESIZE(3, 580, 24, '客户往来明细单据打印')
          LODOP.PREVIEW()
        } else {
          let topDistance = 5 //距离顶部距离
          LODOP.NEWPAGEA()
          LODOP.ADD_PRINT_TEXT(topDistance, 20, 580, 40, '*慧台账进销存*')
          LODOP.SET_PRINT_STYLEA(0, 'FontSize', 15)
          LODOP.SET_PRINT_STYLEA(0, 'FontName', '微软雅黑')

          topDistance += 50
          LODOP.ADD_PRINT_TEXT(topDistance, 5, 580, 40, '开单时间：')
          LODOP.SET_PRINT_STYLEA(0, 'FontSize', 8)
          LODOP.SET_PRINT_STYLEA(0, 'FontName', '微软雅黑')

          topDistance += 1
          LODOP.ADD_PRINT_TEXT(topDistance, 55, 130, 40, this.order.createTime)

          topDistance += 20
          LODOP.ADD_PRINT_TEXT(topDistance, 5, 580, 40, '订单编号：')
          LODOP.SET_PRINT_STYLEA(0, 'FontSize', 8)
          LODOP.SET_PRINT_STYLEA(0, 'FontName', '微软雅黑')

          topDistance += 1
          LODOP.ADD_PRINT_TEXT(topDistance, 55, 130, 60, this.order.buyOrderNo)

          topDistance += 40
          LODOP.ADD_PRINT_TEXT(topDistance, 0, 360, 54, '---------------------------------------------')

          topDistance += 20
          LODOP.ADD_PRINT_TEXT(topDistance, 42, 580, 40, '【客户信息】')
          LODOP.SET_PRINT_STYLEA(0, 'FontSize', 12)
          LODOP.SET_PRINT_STYLEA(0, 'FontName', '微软雅黑')

          topDistance += 30
          LODOP.ADD_PRINT_TEXT(topDistance, 5, 580, 40, '店铺名称：')
          LODOP.SET_PRINT_STYLEA(0, 'FontSize', 10)
          LODOP.SET_PRINT_STYLEA(0, 'FontName', '微软雅黑')

          let height_clientName = 0
          if (client.clientName.length > 9) {
            height_clientName += (Math.ceil(client.clientName.length / 9) - 1) * 17
          }

          topDistance += 3
          LODOP.ADD_PRINT_TEXT(topDistance, 65, 120, height_clientName, client.clientName)

          if (client.clientName.length > 9) {
            topDistance += Math.ceil(client.clientName.length / 9) * 17
          } else {
            topDistance += 20
          }

          LODOP.ADD_PRINT_TEXT(topDistance, 5, 580, 40, '客户名称：')
          LODOP.SET_PRINT_STYLEA(0, 'FontSize', 10)
          LODOP.SET_PRINT_STYLEA(0, 'FontName', '微软雅黑')

          let height_clientPerson = 0
          if (client.clientPerson.length > 9) {
            height_clientPerson += Math.ceil(client.clientPerson.length / 9) * 17
          }

          topDistance += 3
          LODOP.ADD_PRINT_TEXT(topDistance, 65, 120, height_clientPerson, client.clientPerson)

          if (client.clientPerson.length > 9) {
            topDistance += Math.ceil(clinet.clientPerson.length / 9) * 17
          } else {
            topDistance += 20
          }

          LODOP.ADD_PRINT_TEXT(topDistance, 5, 580, 40, '联系方式：')
          LODOP.SET_PRINT_STYLEA(0, 'FontSize', 10)
          LODOP.SET_PRINT_STYLEA(0, 'FontName', '微软雅黑')

          topDistance += 3
          LODOP.ADD_PRINT_TEXT(topDistance, 65, 120, 40, client.clientPhone)

          topDistance += 30

          LODOP.ADD_PRINT_TEXT(topDistance, 0, 360, 54, '---------------------------------------------')

          topDistance += 20
          LODOP.ADD_PRINT_TEXT(topDistance, 42, 580, 40, '【订单详情】')
          LODOP.SET_PRINT_STYLEA(0, 'FontSize', 12)
          LODOP.SET_PRINT_STYLEA(0, 'FontName', '微软雅黑')

          topDistance += 30

          this.orderDetail.map((item, index) => {
            LODOP.ADD_PRINT_TEXT(topDistance, 5, 580, 40, '商品名称：')
            LODOP.SET_PRINT_STYLEA(0, 'FontSize', 10)
            LODOP.SET_PRINT_STYLEA(0, 'FontName', '微软雅黑')

            let height_goodsName = 0
            if (item.goodsName.length > 9) {
              height_goodsName += Math.ceil(item.goodsName.length / 9) * 17
            }

            topDistance += 3
            LODOP.ADD_PRINT_TEXT(topDistance, 65, 120, height_goodsName, item.goodsName)

            if (item.goodsName.length > 9) {
              topDistance += Math.ceil(item.goodsName.length / 9) * 17
            } else {
              topDistance += 20
            }

            LODOP.ADD_PRINT_TEXT(topDistance, 5, 580, 40, '包装规格：')
            LODOP.SET_PRINT_STYLEA(0, 'FontSize', 10)
            LODOP.SET_PRINT_STYLEA(0, 'FontName', '微软雅黑')

            let height_packingSpecification = 0
            if (item.packingSpecification.length > 9) {
              height_packingSpecification += Math.ceil(item.packingSpecification.length / 9) * 17
            }

            topDistance += 3
            LODOP.ADD_PRINT_TEXT(topDistance, 65, 120, height_packingSpecification, item.packingSpecification)

            if (item.packingSpecification.length > 9) {
              topDistance += Math.ceil(item.packingSpecification.length / 9) * 17
            } else {
              topDistance += 20
            }

            if(item.categoryId.toString()[0] === '2' || item.categoryId.toString()[0] === '7') {
              LODOP.ADD_PRINT_TEXT(topDistance, 5, 580, 40, '施用范围：')
              LODOP.SET_PRINT_STYLEA(0, 'FontSize', 10)
              LODOP.SET_PRINT_STYLEA(0, 'FontName', '微软雅黑')

              let height_rangeSpecification = 0
              if (item.useRange.length > 9) {
                height_rangeSpecification += Math.ceil(item.useRange.length / 9) * 17
              }

              topDistance += 3
              LODOP.ADD_PRINT_TEXT(topDistance, 65, 120,height_rangeSpecification, item.useRange)

              if (item.useRange.length > 9) {
                topDistance += Math.ceil(item.useRange.length / 9) * 17
              } else {
                topDistance += 20
              }
            }

            LODOP.ADD_PRINT_TEXT(topDistance, 5, 580, 40, '商品单价：')
            LODOP.SET_PRINT_STYLEA(0, 'FontSize', 10)
            LODOP.SET_PRINT_STYLEA(0, 'FontName', '微软雅黑')

            topDistance += 3
            LODOP.ADD_PRINT_TEXT(topDistance, 65, 120, 45, '￥' + item.goodsPrice)

            topDistance += 20
            LODOP.ADD_PRINT_TEXT(topDistance, 5, 580, 40, '商品数量：')
            LODOP.SET_PRINT_STYLEA(0, 'FontSize', 10)
            LODOP.SET_PRINT_STYLEA(0, 'FontName', '微软雅黑')

            let unit = ''
            if (item.buyPackageLevel == 1) {
              unit = item.middleUnitName
            } else if (item.buyPackageLevel == 2) {
              unit = item.bigUnitName
            }

            topDistance += 3
            LODOP.ADD_PRINT_TEXT(topDistance, 65, 120, 45, item.goodsNumber + unit)

            topDistance += 20
            LODOP.ADD_PRINT_TEXT(topDistance, 5, 172, 40, '————————')

            topDistance += 20
            LODOP.ADD_PRINT_TEXT(topDistance, 5, 580, 40, '小计：')
            LODOP.SET_PRINT_STYLEA(0, 'FontSize', 10)
            LODOP.SET_PRINT_STYLEA(0, 'FontName', '微软雅黑')

            topDistance += 3
            LODOP.ADD_PRINT_TEXT(topDistance, 65, 120, 45, '￥' + item.totalPrice)

            if (index != this.orderDetail.length - 1) {
              topDistance += 20
              LODOP.ADD_PRINT_TEXT(topDistance, 15, 172, 40, '*************************')
              topDistance += 20
            }

          })

          topDistance += 25
          LODOP.ADD_PRINT_TEXT(topDistance, 55, 172, 40, '=========================')

          topDistance += 20
          LODOP.ADD_PRINT_TEXT(topDistance, 85, 580, 40, '应付：')
          LODOP.SET_PRINT_STYLEA(0, 'FontSize', 10)
          LODOP.SET_PRINT_STYLEA(0, 'FontName', '微软雅黑')

          topDistance += 3
          LODOP.ADD_PRINT_TEXT(topDistance, 120, 120, 45, '￥' + this.order.accountsPayable)

          topDistance += 20
          LODOP.ADD_PRINT_TEXT(topDistance, 85, 580, 40, '实付：')
          LODOP.SET_PRINT_STYLEA(0, 'FontSize', 10)
          LODOP.SET_PRINT_STYLEA(0, 'FontName', '微软雅黑')

          topDistance += 3
          LODOP.ADD_PRINT_TEXT(topDistance, 120, 120, 45, '￥' + this.order.paymentAmount)

          topDistance += 20
          LODOP.ADD_PRINT_TEXT(topDistance, 0, 360, 54, '---------------------------------------------')

          topDistance += 20
          LODOP.ADD_PRINT_TEXT(topDistance, 42, 580, 40, '【店铺信息】')
          LODOP.SET_PRINT_STYLEA(0, 'FontSize', 12)
          LODOP.SET_PRINT_STYLEA(0, 'FontName', '微软雅黑')

          topDistance += 30
          LODOP.ADD_PRINT_TEXT(topDistance, 5, 580, 40, '店铺名称：')
          LODOP.SET_PRINT_STYLEA(0, 'FontSize', 10)
          LODOP.SET_PRINT_STYLEA(0, 'FontName', '微软雅黑')

          let tenantInfo = LocalStorage.get(TENANT_INFO)

          let height_tenantName = 0
          if (tenantInfo.tenantName.length > 9) {
            height_tenantName += Math.ceil(tenantInfo.tenantName.length / 9) * 17
          }

          topDistance += 3
          LODOP.ADD_PRINT_TEXT(topDistance, 65, 120, height_tenantName, tenantInfo.tenantName)

          if (tenantInfo.tenantName.length > 9) {
            topDistance += Math.ceil(tenantInfo.tenantName.length / 9) * 17
          } else {
            topDistance += 20
          }

          LODOP.ADD_PRINT_TEXT(topDistance, 5, 580, 40, '联系方式：')
          LODOP.SET_PRINT_STYLEA(0, 'FontSize', 10)
          LODOP.SET_PRINT_STYLEA(0, 'FontName', '微软雅黑')

          topDistance += 3
          LODOP.ADD_PRINT_TEXT(topDistance, 65, 120, 40, tenantInfo.account)

          if (this.order.orderType == 1) {
            topDistance += 20
            LODOP.ADD_PRINT_TEXT(topDistance, 0, 360, 54, '---------------------------------------------')

            topDistance += 20
            LODOP.ADD_PRINT_TEXT(topDistance, 42, 580, 40, '【处方信息】')
            LODOP.SET_PRINT_STYLEA(0, 'FontSize', 12)
            LODOP.SET_PRINT_STYLEA(0, 'FontName', '微软雅黑')

            topDistance += 30
            LODOP.ADD_PRINT_TEXT(topDistance, 5, 580, 40, '处方名称：')
            LODOP.SET_PRINT_STYLEA(0, 'FontSize', 10)
            LODOP.SET_PRINT_STYLEA(0, 'FontName', '微软雅黑')

            let recipeName = '[' + (this.recipe.recipeType == 0 ? '常规处方' : this.recipe.recipeType == 1 ? '专家处方' : this.recipe.recipeType == 2 ? '门店处方' : '无') + '] ' + this.recipe.recipeName

            let height_recipeName = 0
            if (recipeName.length > 9) {
              height_recipeName += Math.ceil(recipeName.length / 9) * 17
            }

            topDistance += 3
            LODOP.ADD_PRINT_TEXT(topDistance, 65, 120, height_recipeName, recipeName)

            if (recipeName.length > 9) {
              topDistance += Math.ceil(recipeName.length / 9) * 17
            } else {
              topDistance += 20
            }

            LODOP.ADD_PRINT_TEXT(topDistance, 5, 580, 40, '作物：')
            LODOP.SET_PRINT_STYLEA(0, 'FontSize', 10)
            LODOP.SET_PRINT_STYLEA(0, 'FontName', '微软雅黑')

            let height_cropCategoryName = 0
            if (this.recipe && this.recipe.cropCategoryName && this.recipe.cropCategoryName.length > 9) {
              height_cropCategoryName += Math.ceil(this.recipe.cropCategoryName.length / 9) * 17
            }

            topDistance += 3
            LODOP.ADD_PRINT_TEXT(topDistance, 65, 120, height_cropCategoryName, this.recipe.cropCategoryName)

            if (this.recipe && this.recipe.cropCategoryName && this.recipe.cropCategoryName.length > 9) {
              topDistance += Math.ceil(this.recipe.cropCategoryName.length / 9) * 17
            } else {
              topDistance += 20
            }

            LODOP.ADD_PRINT_TEXT(topDistance, 5, 580, 40, '防治对象：')
            LODOP.SET_PRINT_STYLEA(0, 'FontSize', 10)
            LODOP.SET_PRINT_STYLEA(0, 'FontName', '微软雅黑')

            let height_preventionName = 0
            if (this.recipe && this.recipe.preventionName && this.recipe.preventionName.length > 9) {
              height_preventionName += Math.ceil(this.recipe.preventionName.length / 9) * 17
            }

            topDistance += 3
            LODOP.ADD_PRINT_TEXT(topDistance, 65, 120, height_preventionName, this.recipe.preventionName)

            if (this.recipe && this.recipe.preventionName && this.recipe.preventionName.length > 9) {
              topDistance += Math.ceil(this.recipe.preventionName.length / 9) * 17
            } else {
              topDistance += 20
            }

            LODOP.ADD_PRINT_TEXT(topDistance, 5, 580, 40, '注意事项：')
            LODOP.SET_PRINT_STYLEA(0, 'FontSize', 10)
            LODOP.SET_PRINT_STYLEA(0, 'FontName', '微软雅黑')

            let height_matters = 0
            if (this.recipe && this.recipe.matters && this.recipe.matters.length > 9) {
              height_matterse += Math.ceil(this.recipe.matters.length / 9) * 17
            }

            topDistance += 3
            LODOP.ADD_PRINT_TEXT(topDistance, 65, 120, height_matters, this.recipe.matters)

            if (this.recipe && this.recipe.matters && this.recipe.matters.length > 9) {
              topDistance += Math.ceil(this.recipe.matters.length / 9) * 17
            } else {
              topDistance += 20
            }
          }

          //设定打印页面大小
          LODOP.SET_PRINT_PAGESIZE(3, 580, 24, '客户往来明细单据打印')
          LODOP.PREVIEW()
        }
      },
      getAuthorityMenu (id) {
        return Tool.authorityUserResources(id)
      },
    }
  }
</script>

<style scoped>
  /deep/ .el-dialog {
    border-radius: 10px;
  }

  /deep/ .el-button.is-round {
    padding: 8px;
  }

  /deep/ .el-dialog__header {
    padding: 0;
  }

  /deep/ .el-dialog--center .el-dialog__body {
    background: #d2d3d04d;
  }

  /deep/ .el-dialog__body {
    padding: 10px;
  }

  /deep/ .el-dialog__headerbtn .el-dialog__close {
    color: white;
    font-size: 24px;
  }

  .player-btn {
    font-size: 24px;
  }

  .header-title {
    background-color: #e52a33;
    color: white;
    font-size: 20px;
    display: inline-block;
    width: 100%;
    line-height: 60px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }

  .header-title .title {
    float: left;
    cursor: pointer;
    margin-left: 30px;
  }

  .verticalLine {
    position: absolute;
    top: 20px;
    float: left;
    left: 150px;
    width: 3px;
    height: 20px;
    border-radius: 40px;
    background-color: #ffffff;
  }

  .header-title .titleDesc {
    float: left;
    margin-left: 30px;
    font-size: 18px;
    line-height: 30px;
    cursor: pointer;
  }

  .header-title .supplierName {
    float: left;
    bottom: 5px;
    margin-left: 10px;
    color: #eeeeee;
    height: 40px;
    text-decoration: none;
    cursor: pointer;
  }

  .header-title .supplierName:hover, .header-title .supplierName:focus {
    color: #f6e483;
  }

  /deep/ .el-dialog__close:before {
    color: #ffffff;
  }

  /*搜索*/
  .showCheck {
    float: left;
    line-height: 40px;
    font-size: 16px;
    margin-right: 10px;
    height: 40px;
    max-width: 585px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .showCheck span {
    color: rgba(255, 102, 0, 0.9);
  }

  .navRight {
    float: right;
  }

  /*表格*/
  .orderNav {
    position: relative;
    width: 100%;
    margin-bottom: 50px;
  }

  .searchInput {
    width: 300px;
    float: left;
    margin-right: 10px;
  }

  .searchBtn {
    margin-right: 10px;
    float: left;
  }


  /*订单表格*/
  .orderTable {
    margin-bottom: 10px;
  }

  /deep/ .el-table .el-table__cell {
    padding: 6px 0;
  }

  .remark div {
    text-align: left;
  }

  .cartNum {
    color: #e52a33 !important;
  }

  /*小计*/
  .bottomMoney {
    margin-top: 20px;
    margin-right: 10px;
    /*float: left;*/
  }

  .bottomBox {
    margin-top: 25px;
    min-height: 80px;
  }

  .bottomBox .title {
    font-weight: 700;
    color: #000000;
  }

  .bottomBox span {
    float: left;
    margin-left: 10px;
    line-height: 30px;
  }

  .totalMoneyParent {
    width: 100%;
    display: flex;
  }

  .totalMoney {
    width: 50%;
    margin-top: -5px;
  }

  .timeCheckBox {
    /*display: inline-block;*/
    margin-top: -5px;
    width: 100%;
  }

  .timeCheckBox .label {
    float: left;
    color: black;
    line-height: 40px;
    font-size: 15px;
  }

  .timeCheckBox .timeCheck {
    float: left;
  }

  .timePickers {
    width: 220px !important;
  }

  .totalMoney .label {
    float: left;
    color: black;
    line-height: 40px;
    font-size: 15px;
  }

  /*.verticalRemarkLine{
    position: absolute;
    width: 2px;
    background: #dddddd;
    height: 100%;
    top: 0;
    left: 300px;
  }*/
  .bottomBox {
    border: 1px solid #dddddd;
    background: rgb(249, 249, 249);
    float: left;
    border-radius: 10px;
    width: 100%;
  }

  .bottomBox .remarkTitle {
    color: black;
    font-size: 15px;
    line-height: 14px;
    padding: 0 10px;
    margin-top: 15px;
    width: 100%;
  }

  .bottomBox .remarkDesc {
    float: left;
    color: black;
    font-size: 15px;
    line-height: 25px;
    text-align: left;
    padding: 10px 10px 0 10px;

  }

  .totalPrice {
    float: left;
    font-size: 18px;
    font-weight: 700;
    color: #e92322;
    line-height: 40px;
    position: relative;
  }

  .totalPriceBlack {
    float: left;
    font-size: 18px;
    font-weight: 700;
    color: black;
    line-height: 40px;
    position: relative;
  }

  .totalPrice:after {
    position: absolute;
    content: "";
    width: 100%;
    height: 2px;
    background: #e92322;
    left: 0;
    bottom: 5px;
  }

  .creditMoneyBox {
    margin-left: 10px;
  }

  .moneyDesc {
    text-align: left;
    margin-left: 10px;
    margin-bottom: 10px;
  }

  .blackTheme {
    color: #606266;
  }

  .blackTheme:after {
    background: #606266;
  }

  .rightBox {
    /*margin-right: 30px;*/
  }
</style>
