import { render, staticRenderFns } from "./ResourceManager.vue?vue&type=template&id=2f59c056&scoped=true"
import script from "./ResourceManager.vue?vue&type=script&lang=js"
export * from "./ResourceManager.vue?vue&type=script&lang=js"
import style0 from "./ResourceManager.vue?vue&type=style&index=0&id=2f59c056&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2f59c056",
  null
  
)

export default component.exports